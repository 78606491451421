import React, { useContext } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
 
import userRoutes from './user-no-authenticated-routes'

const NRoutes = (props) => {
 
    return (
        <Routes> 
            
            { userRoutes }

            <Route path="*"   element={<Navigate to='/login' />}/>
              
        </Routes>
    )
}

export default NRoutes