import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

 
import { Modal } from "react-bootstrap";
import { getFieldLayout, getField, validateInputs } from "../../common/layouts/fields/utility-field";
import InputField from "../../common/layouts/fields/input-field";
import UserContext from '../../user/user-context';
import configTemplates from '../../common/configurations'
import assetConfigs from '../transaction-asset/configs.js'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
 
const elementTemplate = configTemplates.getObjectElement()
const modalConfigs = {

    layout: {

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'asset_id',
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        label: tc.OBJECT_FIELD_TRANSACTION_ASSET_ACTIVE,
                        context: c.SELECTOR_CONTEXT_ACTIVE_LEAF_ACCOUNT,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            
        ]
    }
}

const TransactionExpenseReceivableModal = ({id, service, setShowModal}) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useContext(UserContext)
  
    const [elementCopy, setElementCopy] = useState({})
    const [invalidFields, setInvalidFields] = useState([])
   
    const changeHandler = (value, econfig) => {  
        elementCopy[econfig.name] = value
        setElementCopy({...elementCopy}) 
    }

    const saveHandler = async () => {
        const noValidInputs = validateInputs(elementCopy, modalConfigs)
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }
 
        convertHandler(elementCopy)
    }

    const getColumn = (e, ncols, index) => {
        return getField({ isValid: invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: e, ncolumns: ncols, index, changeHandler, Field: InputField, action:'create'})
    }

    const convertHandler = async (element) => {
        
        
        if(!element.asset_id) {
            setShowModal(false)
        }
          
        const response = await service.convertReceivable({payload: { asset_id: element.asset_id}, item: {id}, user: user }, dispatch)
        if(response.success)
            navigate(`/${assetConfigs.object.basePath}/${response.data.id}/view`)
    }
 
    return (
        <Modal show={true} onHide={()=> setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{tc.MODAL_RECEIVABLE_HEADER}</Modal.Title>
            </Modal.Header>        
            <Modal.Body>
                <p>{tc.MODAL_RECEIVABLE_BODY_MESSAGE}</p>
                <form className="needsValidation" noValidate>
                    { getFieldLayout(modalConfigs, getColumn) }
                </form>     
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={()=> setShowModal(false)}>{tc.BUTTON_CANCEL_LABEL}</button>
                <button type="button" className="btn btn-primary btn-sm" variant="primary" onClick={saveHandler} >{tc.BUTTON_CONVERT_LABEL}</button>
            </Modal.Footer>
        </Modal>
    )
}
export default TransactionExpenseReceivableModal