import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import './quick-links-section'

const QuickLinksSection = ({configs, links}) => {

    const buildLinkComponents = () => {

        if(!links)
            return <div />
        
        return links.map((l, i) => {

            return (
                <li className="nav-item" key={i} >
                    <Link to={`/${l.url}`} key={i} className="nav-link">                            
                        {l.text}
                    </Link>
                </li>
            )
        })
    }

    return (
        <div className="shadow mb-2 bg-body rounded ">
            <div className="card">
                <div className=""> 
                    <ul className="nav">
                        <li className="nav-item">
                            <Link to={`/${configs.object.basePath}`} className="nav-link">            
                                {`Retornar a Lista: ${configs.object.pluralLabel}`}
                            </Link>
                        </li>

                        {
                            buildLinkComponents()
                        }
                    </ul> 
                </div> 
            </div>
        </div>
    )
}

export default QuickLinksSection
