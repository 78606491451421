// system imports
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container'
 
import * as uservice from '../../utilities/services/user-service'
import * as uactions from '../../utilities/redux/actions/user-slice';
import validator from '../user-validator'

// css imports
import './signup-page.css';

const SignupFormPage = (props) => {
    
    const dispatch = useDispatch()
    
    const navigate = useNavigate()
    
    const [error, setError] = useState('')
    
    const [validations, setValidations] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    
    const handleSubmit = async e => { 
        
        try {
            // validation
            if(!validator.validation({type: validator.NEW_USER, parameters: {email, password, repassword}, setInputs: setValidations}))
                return
            
            const payload = {
                username: email, 
                password: password, 
                time_zone: new Date().getTimezoneOffset(), 
                time_zone_text: DateTime.local().zoneName 
            }
            const response = await uservice.add({payload}, dispatch)
            
            if(response.success) 
                navigate(`/signup-scc`)
            else
                setError(response.message)
        }
        catch(e) {
            // if you get some errors
        }
        
    }
    
    const updateHandler = async (e, set) => {
        
        // clean error
        if(error)
            setError()
        
        set(e.target.value)
    }
    
    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5"> 
                    
                    <h2 className="fs-5 fw-normalmb-4">Registro de Usuario</h2>
                    
                    <div className="text-center mb-3">
                        
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Correo</label>
                                    <input type="email" className={`form-control ${validations.email? "is-invalid": ""}`} name="email" id="email" placeholder="nombre@example.com" value={email} required onChange={(e)=>updateHandler(e, setEmail)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Contraseña</label>
                                    <input type="password" className={`form-control ${validations.password? "is-invalid": ""}`} name="password" id="password" placeholder="Contraseña" value={password} required onChange={(e)=>updateHandler(e, setPassword)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Confirmar Contraseña</label>
                                    <input type="password" className={`form-control ${validations.repassword? "is-invalid": ""}`} name="password" id="password" placeholder="Confirmar Contraseña" value={repassword} required onChange={(e)=>updateHandler(e, setRepassword)}/>
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <div className="d-grid my-3">
                                    <button className="btn btn-primary" type="button" onClick={handleSubmit}>Regístrate</button>
                                </div>
                            </div>
                            
                            <p className="m-0 text-secondary text-center">Ya tengo cuenta <Link to={"/login"}>Iniciar sesión</Link></p>
                            
                        </div>
                    </form>
                    
                    {
                        error &&
                        <p className="m-0 text-danger">{error}</p>
                    } 
 
                </div>

                
            </div>
        </AnonymousFormContainer>
    );
}

export default SignupFormPage;