import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";
 
import * as s from '../../utilities/services/user-profile-service' 
 
import * as tc from '../../common/configurations/text-constants'
 
const EnableMultiCurrency = ({showModal, setShowModal, user}) => {
   
    const item = useSelector((state) => state.userProfile.item)

    const [elementCopy, setElementCopy] = useState({...item})
    const [invalidFields, setInvalidFields] = useState([])

    const dispatch = useDispatch()
     
    const saveHandler = async () => { 
        const response = await s.update({payload: {...elementCopy, is_multicurrency: true}, item, user: user }, dispatch)
        if(response.success) {
            setShowModal(false) 
        }
    }
 
     
    return (
        <Modal show={showModal} onHide={()=> setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Habilitar Modo MultiMoneda</Modal.Title>
            </Modal.Header>        
            <Modal.Body>

                <p className="text-danger fw-bold">IMPORTANTE!</p>
                <p className="text-danger">El modo multimoneda agrega varios campos relacionados con la moneda en que se realiza la transaccion. Si usted solo realiza operaciones en su moneda no active este modo</p>
                <p className="text-danger">Despues de activado este modo no va a ser posible modificar la moneda del usuario</p>
                <p className="text-danger">Esta configuración es irreversible.</p>
 
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={()=> setShowModal(false)}>{tc.BUTTON_CANCEL_LABEL}</button>
                <button type="button" className="btn btn-primary btn-sm" variant="primary" onClick={saveHandler} >{tc.BUTTON_ENABLE_LABEL}</button>
            </Modal.Footer>
        </Modal>

        
    )
}

export default EnableMultiCurrency