import React, { Fragment, useContext, useState } from "react";
import { Modal } from "react-bootstrap";

import InputField from "../../fields/input-field";
import FloatMessage from "../../../float-messages";
import { getField, getFieldLayout, validateInputs } from "../../fields/utility-field";
import UserContext from '../../../../user/user-context';

const CompactNewPage = ({configs, setShowModal, defaultItem, message, completedHandler}) => {
 
    const user = useContext(UserContext)
    
    const [elementCopy, setElementCopy] = useState(defaultItem? defaultItem: {})    
    const [invalidFields, setInvalidFields] = useState([])
    const [saveApplied, setSaveApplied] = useState(false)
     
    const changeHandler = (value, configElement) => { 
 
        elementCopy[configElement.name] = value
        setElementCopy({...elementCopy}) 
    }
 

    const saveHandler = (e) => {

        setSaveApplied(true)

        const noValidInputs = validateInputs(elementCopy, configs)
         
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }

        // save changes and go to the list
        configs.actions.add.handler({element: elementCopy}) 
    }
 
    const getColumn = (e, ncols, index) => {
        return getField({ isValid: !saveApplied || invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: {...e, isVisible: e.isVisible && e.isCreatable}, ncolumns: ncols, index, changeHandler, Field: InputField, action:'create'})
    }
 
    return (
        <Modal show={true} onHide={()=> setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{`New ${configs.object.label}`}</Modal.Title>
            </Modal.Header>        
            <Modal.Body>
                <form className="needsValidation" noValidate>
                    <FloatMessage message={message}/>

                    { getFieldLayout(configs, getColumn, defaultItem) }
                </form>            
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={()=> setShowModal(false)}>Cancel</button>
                <button type="button" className="btn btn-danger btn-sm" variant="primary" onClick={saveHandler} >Save</button>
            </Modal.Footer>
        </Modal> 
    )
}

export default CompactNewPage