import { createSlice, current } from '@reduxjs/toolkit'

const transactionAutomatedSlice = createSlice({
    name: 'transaction-automated-template',
    initialState: { loading: false, list: [], item: undefined, itemId: undefined, message: undefined, error: undefined },
    reducers: {
       
      get(state, action) {
        state.loading = true
      },

      getResponse(state, action) {
        state.loading = false
        state.item = action.payload
      },

      getAll(state, action) {
        state.loading = true
      },

      getAllResponse(state, action) {
        state.loading = false
        state.list = action.payload
      },

      getMore(state, action) {
        state.loading = true
      },
      
      getMoreResponse(state, action) {
        state.loading = false
        if(action.payload.length > 0)
            state.list = [...state.list, ...action.payload]
      },

      refurbish(state, action) {
        state.loading = true
      },

      refurbishResponse(state, action) {
        state.loading = false
      },

      noapplied(state, action) {
        state.loading = true
      },

      noappliedResponse(state, action) {
        state.loading = false
      },
  
      error(state, action) {
          state.loading = false
          state.error = action.payload
  
          console.log(action.payload)
      }
    }
})

export const { 
    get, getResponse,
    getAll, getAllResponse,
    getMore, getMoreResponse,
    refurbish, refurbishResponse,
    noapplied, noappliedResponse,
    error
} = transactionAutomatedSlice.actions;

export default transactionAutomatedSlice.reducer