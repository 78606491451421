import React, { useEffect, Fragment, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import ClonePageHeader from "./clone-page-header";
import ClonePageBody from "./clone-page-body";
import NotAvailableComponent from "../../statuses/not-available-component";
import LoadingComponent from "../../statuses/loading-component";

import UserContext from '../../../../user/user-context';
  
const ClonePage = ({service, children, configs, message}) => {

    const user = useContext(UserContext)

    const { id } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)

    document.title = `${item? item.name: configs.object.label}`
    
    configs.actions.clone.handler = async ({element}) => { 

        const response = await service.clone({payload: element, user: user }, dispatch)
        if(response.success)
            navigate(`/${configs.object.basePath}/${response.data.id}/view`)
    }
  
    useEffect(() => {
        service.get({payload: {id}, user }, dispatch)
    }, [])
 
    if(loading)
        return <LoadingComponent configs={configs} isText={true}/>

    if(!item)
        return <NotAvailableComponent configs={configs} />
        
    return ( 
        <Fragment>

            <ClonePageHeader user={user} configs={configs} element={item}/>

            <ClonePageBody user={user} element={item} body={children} configs={configs} message={message}/> 
            
        </Fragment>
    )
}

export default ClonePage