import React, { useEffect, useState } from "react";
import Select, { components } from 'react-select';

import * as pickc from "../../common/configurations/picklist-constants"
 
const FilterOperatorSelector = ({user, value, type, onChange}) => {
 
    const [list, setList] = useState([])
    const [internalValue, setInternalValue] = useState()
     
    // customization of Select component options
    const Option = ({ ...props }) => {                
        return <components.Option {...props}>
            <div>{props.label}</div>
        </components.Option>
    } 

    const valueOptions = () => {
  
        if(!list)
            return []

        return list.map((e)=> { 
 
            return { 
                value: e.id, 
                label: e.name
            }
        })
 
    }
     
    useEffect(() => {

        const load = async () => {
            setList(pickc.FILTER_OPERATIONS.filter(x => x.allowType.indexOf(type) != -1))
        }

        load()
 
        setInternalValue(value)
        
    }, [type, value])
 
    return <Select options={valueOptions()} components={{ Option }} isSearchable ={true} value={internalValue || ''} onChange={(v) => {onChange(v); setInternalValue(v)}} /> 
}

export default FilterOperatorSelector