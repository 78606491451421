import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { initMercadoPago } from '@mercadopago/sdk-react'

import PayPalComponent from "./paypal2";
import MercadoPagoComponent from "./mercadopago"
import PayUComponent from "./payu"


import UserContext from '../user-context';

import * as s from '../../utilities/services/user-tool-service'

import './payment-container.css'

const PaymentContainer = ({}) => {

    const user = useContext(UserContext)
    const [info, setInfo] = useState()

    document.title = `Pago`

    const dispatch = useDispatch()
 
    useEffect(() => { 
        const load = async () => {
            const response = await s.info({user}, dispatch)
            if(response.success)
                setInfo(response.data)
        }

        load()

        // initMercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY);
        
    }, [])
    

    if(!info)
        return <div />

    if(info.payment.is_trial) {
        return (
            <div className="container payment-container">
                <p>
                    ¡Hola! Te recordamos que actualmente estás en un período de prueba de nuestro servicio.
                </p>
                <p>
                    Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos. ¡Disfruta explorando todas las funcionalidades que ofrecemos!
                </p>
                
            </div>
        )
    }

    if(info.payment.was_paid) {
        return (
            <div className="container payment-container">
                <p>
                    ¡Hola! Te informamos que el pago de tu período ha sido realizado con éxito
                </p>
                <p>
                    Tu suscripción estará activa hasta el: <strong>{info.payment.next_payment}</strong>
                </p>
                <p>
                    Gracias por tu confianza y por ser parte de nuestra comunidad. Si tienes alguna duda o necesitas asistencia, no dudes en contactarnos. ¡Disfruta de nuestros servicios!
                </p>
            </div>
        )
    }
        
    return (
        <div className="container payment-container">
            <div className="row justify-content-center"> 
                {
                    // info && <MercadoPagoComponent user={user} dispatch={dispatch} info={info}/>
                    // info && <PayPalComponent user={user} dispatch={dispatch} info={info}/>
                    info && <PayUComponent user={user} dispatch={dispatch} info={info}/>
                }
            </div>
        </div>
    )
}

export default PaymentContainer