// system imports
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
 
import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container'


// css imports
import './signup-page.css';

const SignupSuccessPage = (props) => {
     
    return (
        <AnonymousFormContainer>
            <div>
                
                <h1>¡Registro Exitoso!</h1>
                <p>Gracias por registrarte. Tu cuenta ha sido creada exitosamente.</p>
                
                <Link to="/login" className="m-1">Ir al inicio</Link>
                
            </div>
        </AnonymousFormContainer>
    );
}

export default SignupSuccessPage;