import React, { useState, useLayoutEffect, useRef, useEffect, Fragment, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import _ from "lodash" 

import Search from "../../search/search";
import BasicTreeItem from './basic-tree-item'
import BasicTreeHeader from "./basic-tree-header";
import UserContext from '../../../user/user-context';

import './basic-tree.css'

import * as tc from '../../configurations/text-constants'

const BasicTree = ({service, configs}) => {
    
    const user = useContext(UserContext)

    const dispatch = useDispatch()
    const navigate = useNavigate()
  
    const [text, setText] = useState('')
    const [internalRoot, setInternalRoot] = useState()

    document.title = `${configs.object.pluralLabel}`
       
    const moveHandler = async (item, newparentid) => {
        const response =  await service.update({payload: {...item, parent_id: newparentid}, user: user}, dispatch)

        if(response.success)
            navigate(0)
    }

    configs.actions.move.handler = moveHandler

    const setDefaultProperties = (node) => {

        node.closed = false; 

        if(!node.children)
            return

        // opened
        // searched
        for (const key in node.children) {
            setDefaultProperties(node.children[key])
        }

        return node
    }

    const searchHandler = (text) => { 
        search(internalRoot, text) 
        setInternalRoot(internalRoot)
        setText(text) 
    }
 
    const search = (node, text) => { 
        setDefaultProperties(node)
        searchTextRecursive(node, text) 
    }
  
    const searchTextRecursive = (node, text) => {
        // matched -- empty text
        if(!text) {
            return true
        }

        // its children matched
        let oneChildMatched = false
        if(node.children) {  
            
            let child
            
            for(let index in node.children) {
 
                child = node.children[index]
                oneChildMatched |= searchTextRecursive(child, text)
            }
        }

        // open current node if one of its children is visible
        if(!oneChildMatched) {
            node.closed = true 
        }

        // matched .. contains the text
        if(node.name.toLowerCase().indexOf(text.toLowerCase()) !== -1)
            return true
          
        return oneChildMatched
    }
 
    const getTreeHeaders = (configs) => {
 
        return configs.treelist.items.map((c, i) => { 
            return (
                <th key={i} className={`tree-list-column ${c.isCompactVisible? '': 'd-none d-md-table-cell'}`}> 
                    {c.label}
                </th>
            )
        })
    }
 
    useEffect(()=> {
        const load = async () => {
            const response = await service.getTree({user: user}, dispatch)
             
            if(response.success)
                setInternalRoot( _.cloneDeep(response.data))
        }
 
        load()
        
    }, [])
 
    return (
         
        <Fragment>

            <BasicTreeHeader configs={configs} />
             
            <div className="custom-tree-index"> 
                <div className="card"> 
                    <div className="card-body">
                        <div className="vstack gap-2">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <Search handler={searchHandler} placeholder={'Buscar...'}/>
                                </div>
                            </div>

                            <div className="row">
                                 
                                <div className="container-fluid" >
                                    <table className="table table-bordered text-uppercase table-tree-header"> 
                                        <thead>
                                            <tr className={`text-center table-secondary `}>
                                                <th>{tc.OBJECT_FIELD_GENERIC_NAME}</th>
                                                {
                                                    getTreeHeaders(configs)
                                                }
                                            </tr>
                                        </thead>
                                    </table>

                                    {
                                        !internalRoot &&
                                        <div />
                                    }

                                </div>
                                 
                                {
                                    internalRoot &&
                                    <div className="container-fluid">
                                        <table className="table table-hover table-bordered">                                
                                            <tbody>
                                                <BasicTreeItem pitem={internalRoot} searchText={text} level={0} configs={configs}></BasicTreeItem>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Fragment>
        
    )
}

export default BasicTree