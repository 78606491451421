// system imports
import axios from "axios";
import * as uactions from '../redux/actions/user-slice';
import * as shelper from './helper'

import * as gservice from './_generic-service'
 
const API_URL = shelper.serverDomain();

// create new user
const add = async (params, dispatch) => {

    try {
        // dispatch request token
        dispatch(uactions.add(params));

        const url = API_URL + '/auth-users'
        const xparams = params.payload
        const response = await axios.post(url, xparams);
   
        return gservice.getResponse(response, uactions.loginResponse, dispatch)
    }
    catch(e) {
        return gservice.getError(e, uactions.error, dispatch)
    }
    
}

// request for a new token - login
const login = async (params, dispatch) => {

    try {
        // dispatch request token
        dispatch(uactions.login(params));

        const url = API_URL + '/auth-token'
        const xparams = {
            username: params.username,
            password: params.password,
            rememberMe: params.rememberMe
        }
        const response = await axios.post(url, xparams);
  
        return gservice.getResponse(response, uactions.addResponse, dispatch)
    }
    catch(e) {
        return gservice.getError(e, uactions.error, dispatch)
    }
    
}

// request for a new token - login
const requestPassword = async (params, dispatch) => {

    try {
        
        const url = API_URL + '/auth-users/password/notification'
        const xparams = {
            email: params.email,
        }
        const response = await axios.post(url, xparams);

        if(response.status !== 200) { 
            const message = await response.body()
            dispatch(uactions.error(message));

            return;
        }
       
        return gservice.getResponse(response, null, dispatch)
    }
    catch(e) {
        return gservice.getError(e, uactions.error, dispatch)
    }
    
}

// request for a new token - login
const changePassword = async (params, dispatch) => {

    try {
       
        const url = API_URL + '/auth-users/password/update'
        const xparams = {
            token: params.token,
            password: params.password
        }
        const response = await axios.post(url, xparams);

        if(response.status !== 200) { 
            const message = await response.body()
            dispatch(uactions.error(message));

            return;
        }
       
        return gservice.getResponse(response, null, dispatch)
    }
    catch(e) {
        return gservice.getError(e, uactions.error, dispatch)
    }
    
}

// request for a new token - login
const changePasswordInternal = async (params, dispatch) => {

    try {
    
        const url = API_URL + '/users/password'
        const options = shelper.axiosBearerConfig(params)
        const xparams = { ...params.payload } 

        const response = await axios.post(url, xparams, options);
  
        if(response.status !== 200) { 
            const message = await response.body()
            dispatch(uactions.error(message));

            return;
        }
        
        return gservice.getResponse(response, null, dispatch)
    }
    catch(e) {
        return gservice.getError(e, uactions.error, dispatch)
    }
    
}
// activate user
const activate = async (params, dispatch) => {

    try {
      
        dispatch(uactions.activate(params));

        const url = API_URL + '/auth-token/activate'
        const xparams = {
            ...params
        }
        const response = await axios.post(url, xparams);
  
        return gservice.getResponse(response, uactions.activateResponse, dispatch)
    }
    catch(e) {
        return gservice.getError(e, uactions.error, dispatch)
    }
    
}

// remove old token - logout
const logout = async (user, dispatch) => {

    try {
 
        if(!user)
            return
 
        // dispatch request token
        dispatch(uactions.logout(user.username)); 
        
        const url = API_URL + '/token'
        let config = {
            headers: {
            'Accept': 'application/json',               
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + user.access_token,
            }
        }
        
        const response = await axios.delete(url, config);

        return gservice.getResponse(response, uactions.logoutResponse, dispatch)
    }
    catch(e) {
        return gservice.getError(e, uactions.error, dispatch)
    }
 
}
 
export { add, login, logout, activate, changePasswordInternal, changePassword, requestPassword }