import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

const getStoredUser = () => {
    
    const user = Cookies.get('ut')
     
    try {
        return JSON.parse(user)
    }
    catch(e) {
        return undefined
    }
}
 
const userSlice = createSlice({
    name: 'user',
    initialState: { 
        loading: false, 
        tokenid: undefined, 
        user: getStoredUser(),
        rememberMe: false,
        message: undefined, 
        error: undefined 
    },
    reducers: {
        
        add(state, action) {
            state.loading = true
        },
        
        addResponse(state, action) {  
            state.loading = false
            state.tokenid = action.payload.id
        },
        
        login(state, action) {
            state.loading = true

            // clear localstorage 
            Cookies.remove('ut')
            state.rememberMe = action.payload.rememberMe
        },
        
        loginResponse(state, action) {
            state.loading = false
            state.tokenid = action.payload.id
        },
        
        activate(state, action) {
            state.loading = true
        },
        
        activateResponse(state, action) {
            
            state.loading = false 
            if(action.payload) {
                state.user = action.payload

                // cookie creation
                var expireDate = new Date();
                if(state.rememberMe)
                    expireDate.setFullYear(expireDate.getFullYear() + 1); // Sets the date to one year from today
                else
                    expireDate.setHours(expireDate.getHours() + 1)

                Cookies.set('ut', JSON.stringify(action.payload), { expires: expireDate });

            }
            
        },
        
        logout(state, action) {
            state.loading = true
        },
        
        logoutResponse(state, action) {
            
            Cookies.remove('ut')
            
            state.loading = false
            state.user = undefined
        },
         
        error(state, action) {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    add, addResponse,
    login, loginResponse,
    logout, logoutResponse,
    activate, activateResponse,
    error
} = userSlice.actions;

export default userSlice.reducer