// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/account-asset-slice';
import * as aservice from './account-service'
 
const SERVICE_PATH = 'accounts';

const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
export const { get } = fns
 
export const { remove, update, add } = aservice

const getTree = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/tree/assets`,
        action: actions.getTree,
        actionResponse: actions.getTreeResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
  
const getLeaves = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/assets`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getPaymentLeaves = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/payment_assets`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getReceivableLeaves = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/assets/receivable`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getMoveTargets = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/move-targets/assets`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
 
export { getTree, getLeaves, getReceivableLeaves, getMoveTargets  }