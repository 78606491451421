import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"

import dashboardConfig from "../../data/dashboard/configs"

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'email',
                        label: tc.OBJECT_FIELD_PROFILE_EMAIL,
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        isEditable: false,
                        isCreatable: true,
                    }, 
                    {
                        ...elementTemplate,
                        isVisiable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'dashboard_home_id',
                        label: tc.OBJECT_FIELD_PROFILE_HOME_DASHBOARD,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_DASHBOARD,
                        isSelectorLinked: true, //dashboardConfig.object.basePath,
                        isRequired: false,
                        isEditable: true,
                    }, 
                    {
                        ...elementTemplate,
                        name: 'dashboard_mobile_id',
                        label: tc.OBJECT_FIELD_PROFILE_MOBILE_DASHBOARD,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_DASHBOARD,
                        isSelectorLinked: true, //dashboardConfig.object.basePath,
                        isRequired: false,
                        isEditable: true,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    { 
                        ...elementTemplate,
                        name: 'currency_id',
                        label: tc.OBJECT_FIELD_PROFILE_CURRENCY,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_USER_PROFILE_CURRENCY,
                        isRequired: false,
                        isEditable: true,
                    },
                    {
                        ...elementTemplate,
                        name: 'is_multicurrency',
                        label: tc.OBJECT_FIELD_PROFILE_MULTICURRENCY,
                        type: c.OBJECT_FIELD_DATA_TYPE_BOOLEAN,
                        isEditable: false,
                        isCreatable: false,
                    }, 
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'person-circle',
        enableQuickActions: false
    },

    object: {
        name: 'user-profile',
        label: tc.OBJECT_PROFILE_NAME_LABEL,
        pluralLabel: tc.OBJECT_PROFILE_PLURAL_LABEL,
        basePath: 'user-profiles',
        sliceName: 'userProfile',
    }
}
 
export default configs