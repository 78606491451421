import * as c from './constants'

const PAYMENT_METHODS = [
    {value: c.ACCOUNT_DEFINITION_BANK, label: 'Banco'},
    {value: c.ACCOUNT_DEFINITION_CARD, label: 'Tarjeta de Credito'},
    {value: c.ACCOUNT_DEFINITION_WALLET, label: 'Billetera Digital'}
]

const DASHBOARD_NUMBER_OF_COLUMNS = [
    {value: 1, label: '1'},
    {value: 2, label: '2'}
]

const DATE_FORMATS = [
    {value: 'yyyy/mm/dd', label: 'yyyy/mm/dd'},
    {value: 'dd/mm/yyyy', label: 'dd/mm/yyyy'},
    {value: 't', label: 'today'}
]

const FILTER_OPERATIONS = [
    {
        name: 'Igual',
        id: 'e',
        allowType: ['text', 'number', 'boolean', 'currency', 'date', 'datetime', 'percent', 'selector', 'aggregated_date']
    },
    {
        name: 'Distinto',
        id: 'ne',
        allowType: ['text', 'number', 'boolean', 'currency', 'date', 'datetime', 'percent', 'selector', 'aggregated_date']
    },
    {
        name: 'Mayor',
        id: 'gt',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: 'Mayor o Igual',
        id: 'gte',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: 'Menor',
        id: 'lt',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: 'Menor o Igual',
        id: 'lte',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: 'Contiene',
        id: 'c',
        allowType: ['text', 'textarea', 'textarea_html', 'selector']
    },
    {
        name: 'No Contiene',
        id: 'nc',
        allowType: ['text', 'textarea', 'textarea_html', 'selector']
    }
]


export {
    PAYMENT_METHODS,
    DATE_FORMATS,
    FILTER_OPERATIONS,
    DASHBOARD_NUMBER_OF_COLUMNS
}