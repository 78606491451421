import React, { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import NotAvailableComponent from "../../../layouts/statuses/not-available-component";
import LoadingComponent from "../../../layouts/statuses/loading-component";

import NewChildPageHeader from "./tree-item-new-child-page-header";
import NewChildPageBody from "./tree-item-new-child-page-body";
import UserContext from '../../../../user/user-context';

const NewChildPage = ({service, children, configs}) => {
   
    const { id } = useParams()
    
    const user = useContext(UserContext)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)

    document.title = `Nuevo: ${configs.object.label}`

    configs.actions.add.handler = async ({element}) => {
        const response = await service.add({payload: element, user: user }, dispatch)
 
        if(response.success) 
            navigate(`/${configs.object.basePath}/${response.data.id}/view`)
    }

    useEffect(() => {
        service.get({payload: {id}, user }, dispatch)
    }, [])
  
    if(loading)
        return <LoadingComponent configs={configs} isText={true}/>

    if(!item)
        return <NotAvailableComponent configs={configs} />

    return (
        <Fragment>
            <NewChildPageHeader user={user} configs={configs} />
            <NewChildPageBody user={user} body={children} parent={item} configs={configs}/>
        </Fragment>
    )
}

export default NewChildPage