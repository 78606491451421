import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
 
import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container'


import * as useractions from '../../utilities/redux/actions/user-slice'

import * as uservice from '../../utilities/services/user-service'

const VerificationPage = () => {

    const [code, setCode] = useState('')
    const [error, setError] = useState('')
    const tokenid = useSelector((state) => state.user.tokenid)
    const [time, setTime] = useState(60)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const updateHandler = async (e) => {
        if(error)
            setError() 
         
        setCode(e.target.value)
    }
    const activateHandler = async () => {
        const response = await uservice.activate({id: tokenid, code}, dispatch) 
        if(response.success) {
            navigate('/')
        }
        else {
            setError(response.message)
        }
    }

    const cancelHandler = () => {
        // delete error message if there is
        setError()

        // go to login page
        navigate(`/login`)
    }

    useEffect(() => {

        setError()

        const intervalId = setInterval(() => {
            setTime((currentTime) => {
                if (currentTime <= 0) {
                    clearInterval(intervalId) // Clear interval if time is up
                    cancelHandler()
                    return 0
                }
 
                return currentTime - 1 // Decrement the time
            })
        }, 1000) // Interval set for 1 second

        return () => clearInterval(intervalId)
    }, [])
 
    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                        
                <div className="card-body p-3 p-md-4 p-xl-5">
                    
                    <h2 className="fs-5 fw-normalmb-4">Verificación</h2>

                    <div className="text-center mb-3">
                        
                    </div>

                    <p className="m-0 mb-2 text-secondary">Por favor, introduce el código de verificación que hemos enviado a tu dirección de correo electrónico para continuar</p>
 
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="code" className="form-label">Código</label>
                                    <input type="text"  name="code" id="code" className="form-control" placeholder="Entra el Código" value={code} required onChange={updateHandler}/>
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-secondary btn" type="button" onClick={cancelHandler}>Cancelar</button>
                                    </div>
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-primary btn" type="button" onClick={activateHandler}>Validar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {
                        error &&
                        <p className="m-0 text-danger">{error}</p>
                    } 

                    <div className="mb-3 text-center">
                        <h1>{time}</h1>
                    </div>

                </div>
            </div>
        </AnonymousFormContainer>
    )
}

export default VerificationPage;