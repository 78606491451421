import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from 'react-redux'
import { Nav, NavDropdown } from 'react-bootstrap'
import { Modal } from "react-bootstrap";

import FilterOperatorSelector from "../../../selectors/filter-operator-selector";

import * as tc from "../../../configurations/text-constants";
import * as s from "../../../../utilities/services/listview-service"
import UserContext from '../../../../user/user-context';
 
const LISTVIEW_ACTION_NEW = 'n'
const LISTVIEW_ACTION_UPDATE = 'u'
const LISTVIEW_ACTION_DELETE = 'd'
 
const ListViewFormItem = ({columns, filter, update, number}) => {
 
    const [column, setColumn] = useState()
    const [operator, setOperator] = useState()
    const [value, setValue] = useState('')
   
    const setInternalColumn = (name) => {
        const col = columns.find(c => c.name === name)
        setColumn(col)
        refreshFilter(col, operator, value)
    }

    const setInternalOperator = (op) => {
        setOperator(op)
        refreshFilter(column, op, value)
    }

    const setInternalValue = (v) => {
        setValue(v)
        refreshFilter(column, operator, v)
    }

    const refreshFilter = (column, operator, value) => {
         
        const objfilter = {
            column: column? {name: column.name, data_type: column.type}: undefined,
            operator: operator? {id: operator.value, label: operator.label}: undefined,
            value: value,
            number: number
        }

        update(objfilter)
    }

    const reset = () => {

        setColumn()
        setOperator()
        setValue('')

        refreshFilter({column: undefined, operator: undefined, value: '', number})
    }
 
    useEffect(()=> {
 
        // initial values
        setColumn(filter && filter.column? {name: filter.column.name, type: filter.column.data_type}: undefined)
        setOperator(filter && filter.operator? {value: filter.operator.id, label:  filter.operator.label}: undefined)
        setValue(filter && filter.value? filter.value: '')
        
    }, [filter])
 
    return (
        <div className="row">
            <div className="col mb-3 hstack gap-2">
                <i className="bi bi-x-lg" onClick={reset}></i>
                <select className="form-select" onChange={(e) => setInternalColumn(e.target.value)} value={`${column && column.name}`}>
                    <option key={`-1`} label="---" value={0}/>
                    {
                        columns.map((c, i) => {
                            return  <option key={`${i}`} label={c.label} value={c.name} />
                        })
                    
                    }
                </select>
            </div>
            <div className="col mb-3">
                <FilterOperatorSelector onChange={(v)=> setInternalOperator(v)}  value={operator} type={column && column.type}/>
            </div>
            <div className="col  mb-3">
                <input type="text" className="form-control" value={value} onChange={(e)=> setInternalValue(e.target.value)} />
            </div>
        </div>
    )
}

const ListViewForm = ({configs, isNew, item, close, save}) => {

    const [name, setName] = useState('')

    const [columns, setColumns] = useState([])
    const [filters, setFilters] = useState([])
   
    const saveHandler = (e) => {
        
        const nitem = {...item, name: name, filters: JSON.stringify(filters)}
        
        save(nitem)
 
    }
   
    const deleteHandler = (e) => {
        
    }
   
    const getColumnsFromConfigs = () => {

       
        const columns = []
        configs.layout.sections.map((s) => {
            
            if(!s.elements) return

            s.elements.filter(l => l.isVisible).map((l) => {
                columns.push(l)
            })
        })

        return columns
    }
  
    const updateParent = (data) => { 
        const newfilters = [...filters]
        newfilters[data.number] = {...data}
        setFilters(newfilters)
    }
 
    useEffect(()=> {

        setColumns(getColumnsFromConfigs())
 
        //  new item gonna be created
        if(isNew)
            return

        // set values of the item
        if(!item)
            return
 
        setName(item.name)
        setFilters(JSON.parse(item.filters))
        
    }, [item])

    return (
        <Modal show={true} onHide={close} size="lg">
            <Modal.Header closeButton>
                
            </Modal.Header> 
            <Modal.Body>
                <form className="needsValidation" noValidate>
                
                    <div className="form-group vstack gap-1">
        
                        <div className="row">
                            <div className="col mb-3">
                                <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.target.value)} />
                            </div>
                        </div>

                        <ListViewFormItem columns={columns} update={updateParent} number={0} filter={filters[0]? filters[0]: undefined}/>

                        <ListViewFormItem columns={columns} update={updateParent} number={1} filter={filters[1]? filters[1]: undefined}/>

                    </div>
                </form>
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={close}>{tc.BUTTON_CANCEL_LABEL}</button>
                <button type="button" className="btn btn-primary btn-sm" variant="primary" onClick={saveHandler} >{tc.BUTTON_SAVE_LABEL}</button>
            </Modal.Footer>
        </Modal>
    )
}

const TableListViews = ({configs, viewId}) => {
    
    const [list, setList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [action, setAction] = useState()
    const [listview, setListview] = useState()
     
    const user = useContext(UserContext)
    const dispatch = useDispatch()
    
    useEffect(()=>{
        const load = async () => {
            const response = await s.getAll({payload: {object: configs.object.name}, user: user}, dispatch)
            if(!response.success)
                return
            
            const views = response.data
            setList(views)
 
            let lvToBeSet
            if(viewId)
                lvToBeSet = views.find(lv=> lv.id === viewId)
            else
                lvToBeSet = views.find(lv=> lv.is_default)

            if(!lvToBeSet)
                lvToBeSet = views[0]
 
            setListview(lvToBeSet)
            configs.actions.listviews.handler(lvToBeSet.id)
        }

        load()
        
    }, [showModal, viewId])
 

    const selectHandler = (e) => {
        configs.actions.listviews.handler(e.target.value)
    }

    const navHandler = (action) => {
        setAction(action)
        
        if(action === LISTVIEW_ACTION_NEW || action === LISTVIEW_ACTION_UPDATE) {
            setShowModal(true)
        }
        else if(action === LISTVIEW_ACTION_DELETE){
            // delete and set the default listview
            setShowDeleteModal(true)
        }

    }

    const deleteConfirmationHandler = async () => {
        // show confirmation modal
        await s.remove({payload: {...listview}, user: user}, dispatch)
        setShowDeleteModal(false)
        configs.actions.listviews.handler()
    }

    const togglePinHandler = async () => {
 
        if(!listview)
            return

        const response = await s.update({payload: {...listview, is_default: !listview.is_default}, user: user}, dispatch)
        if(response.success)
            setListview(response.data)
    }

    const saveHandler = async (item) => {

        if(action === LISTVIEW_ACTION_UPDATE) {// it is an update
            await s.update({payload: item, user: user}, dispatch)
            configs.actions.listviews.handler(item.id)
        }
        
        else if(action === LISTVIEW_ACTION_NEW) { // it is an insert
            const response = await s.add({payload: {...item, lvobject: configs.object.name}, user: user}, dispatch)
            if(response.success)
                configs.actions.listviews.handler(response.data.id)
        }

        setShowModal(false)
    }
   
    // listview has not been loaded yet
    if(!listview)
        return (
            <select className="form-select">
                <option label={tc.GENERAL_COMPONENT_MESSAGE__SELECT_LOADING}/>
            </select>
        )

    return (
        <div className="hstack gap-2 listview">
             
            <select className="form-select" onChange={selectHandler} value={listview.id}>
                { 
                    list && list.map((l, i) => {
                        return <option key={i} label={l.name} value={l.id}/>
                    })
                }
            </select>

            <div className="pin" onClick={togglePinHandler}>
                {
                    listview && listview.is_default?
                    <i className="bi bi-pin-fill h6" ></i>:
                    <i className="bi bi-pin-angle h6"></i>
                }
            </div>
             
            <div>
                <Nav className="listview-nav" onSelect={navHandler} >
                    <NavDropdown title={<i className="bi bi-gear h6" ></i>} active={true}  >
                        <NavDropdown.Item eventKey={LISTVIEW_ACTION_NEW}>{tc.BUTTON_ADD_LABEL}</NavDropdown.Item>
                        <NavDropdown.Item disabled={listview && listview.is_standard} eventKey={LISTVIEW_ACTION_UPDATE}>{tc.BUTTON_EDIT_LABEL}</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item disabled={listview && listview.is_standard} eventKey={LISTVIEW_ACTION_DELETE}>{tc.BUTTON_REMOVE_LABEL}</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </div>
             
            {
                showModal && <ListViewForm isNew={action===LISTVIEW_ACTION_NEW} item={listview} configs={configs} close={()=>setShowModal(false)} save={saveHandler}/>
            }

            <Modal show={showDeleteModal} onHide={()=> setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{ tc.MODAL_DELETE_HEADER }</Modal.Title>
                </Modal.Header>        
                <Modal.Body>
                    <p>
                        { tc.MODAL_DELETE_BODY_MESSAGE.replace('[RECORD_NAME]', listview && listview.name) }
                    </p>             
                </Modal.Body>
            
                <Modal.Footer>
                    <button className="btn btn-secondary btn-sm" onClick={()=> setShowDeleteModal(false)}>{tc.BUTTON_CANCEL_LABEL}</button>
                    <button type="button" className="btn btn-danger btn-sm" variant="primary" onClick={deleteConfirmationHandler} >{tc.BUTTON_REMOVE_LABEL}</button>
                </Modal.Footer>
            </Modal>

        </div>
        
    )
}

export default TableListViews

