import React from "react";

import OutputField from "../../layouts/fields/output-field";
import ReportTableViewerRow from "./report-table-viewer-row";
import { getCSSBasedOnType, getSizeCSSBasedOnType } from "../../layouts/fields/utility-field"

const ReportTableViewerBody = ({data}) => {
 
    return (
        <table className="table table-bordered table-hover"> 
            <thead>
                <tr > 
                    {
                        data && data.headers.map((h, i) => {
                            const econfigs = {type: h.data_type}
                            return <th className={`text-uppercase text-center text-truncate ${getSizeCSSBasedOnType(econfigs)}`} key={i}>{h.label}</th>
                        })
                    } 
                </tr>
            </thead> 
            <tbody>
                {
                    
                    data && data.totals.filter(t => t != 0) .length > 0 && 
                    <tr className="table-secondary">
                        {
                            data && data.totals.map((h, i) => {
    
                                const econfigs = {type: data.headers[i].data_type}
                                if(!h)
                                    return <th className="" key={i}></th>

                                return <th className={`${getCSSBasedOnType(econfigs)} ${getSizeCSSBasedOnType(econfigs)}`} key={i}><OutputField value={h} vConfig={econfigs}/></th>
                            })
                        } 
                    </tr>
                }
                

                {
                    data && data.rows.map((r, i) => {
                        return <ReportTableViewerRow data={data} key={i} rdata={r}></ReportTableViewerRow>
                    })
                }
            </tbody>
        </table>
    )
}

export default ReportTableViewerBody