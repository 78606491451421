import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import OutputField from "../../fields/output-field";
import { getCSSBasedOnType, getSizeCSSBasedOnType } from "../../fields/utility-field";
import './table.css';

const TableListItem = ({ element, configs, print }) => {
    
    const renderColumn = (config, index) => {
        const classes = `text-truncate col ${getCSSBasedOnType(config)} ${getSizeCSSBasedOnType(config)}`;
        const content = (
            <>
                {config.isLinked && element.has_files && <i className="bi bi-paperclip"></i>}
                <OutputField value={element[config.name]} vConfig={config}/>
            </>
        );

        if (config.isLinked) {
            return (
                <td className={`hstack gap-2 ${classes}`} key={index}>
                    <Link 
                        to={`/${element.id}`} 
                        style={element.is_standard ? {fontWeight: 'bold', color: 'gray'} : {}}
                        className="text-truncate"
                        title={element.name}
                    >
                        {content}
                    </Link>
                </td>
            );
        }
  
        return <td className={classes} key={index}>{content}</td>;
    };

    return (
        <tr className="row">
            {configs.object.printable && (
                <td className="table-cell-preview">
                    <Link onClick={() => print(element)}>
                        <i className="bi bi-eye list-item-preview" title={`Visualizar ${configs.object.label}`}/>
                    </Link>
                </td>
            )}
            {configs.list.items.map(renderColumn)}
        </tr>
    );
};
   
export default TableListItem;