import React, { Fragment, useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown, Accordion, Modal } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { 
    mdiFaceAgent,
    mdiEmail,
    mdiPhone,
    mdiMenu,
    mdiAccount
} from '@mdi/js';
 
import configs from '../../../utilities/configuration-container'
import * as tc from '../../../common/configurations/text-constants'
import * as uservice from '../../../utilities/services/user-service'
 
import supportTicketConfigs from '../../../supports/ticket/configs'
 
import './authenticated-container.css'
import logo from '../../../logo.png'
import MaterialIcon from "../../../common/icons/material-icon";
 
const ContactUsModal = ({contactUsModal, setContactUsModal}) => {
   
    return (
        <Modal show={contactUsModal} onHide={() => setContactUsModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="hstack gap-3">
                        <Icon path={mdiFaceAgent} size={1} />
                        Contáctanos
                    </div>
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                <div>
                
                    <p>¡Hola! ¿Necesitas ayuda? Estamos aquí para asistirte. Puedes comunicarte con nuestro equipo de soporte de las siguientes maneras:</p>
                    
                    <ol style={{listStyle: 'none'}}>
                        <li style={{paddingBottom: '20px'}}><strong><Icon path={mdiEmail} size={1} /></strong> Si prefieres comunicarte por correo electrónico, envíanos un mensaje a <a href="mailto:soporte@dragonscash.com">soporte@dragonscash.com</a> y te responderemos lo antes posible.</li>
                        <li><strong><Icon path={mdiPhone} size={1} /></strong> ¿Prefieres hablar con alguien? Llámanos o escribenos por whatsapp al <a href="tel:+573134536165">+573134536165</a> y estaremos encantados de ayudarte con cualquier consulta que tengas.</li>
                    </ol>

                    <p>¡No dudes en ponerte en contacto con nosotros! Estamos aquí para asegurarnos de que tengas la mejor experiencia posible.</p>
                </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={() => setContactUsModal(false)}>
                    {tc.BUTTON_CLOSE_LABEL}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const ConfigurationNavDropdownBody = ({user, navigate, dispatch, select}) => {
 
    const logout = async e => {
        await uservice.logout(user, dispatch); 
        navigate('/')
    }

    return (
        <div>
            <NavDropdown.Item  to={`#`} onClick={() => select('contact')}>{'Contáctanos'}</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={`${process.env.REACT_APP_DOCUMENT_URL}`} target="_blank">Documentación</NavDropdown.Item>
 
            <NavDropdown.Divider></NavDropdown.Divider>
            <NavDropdown.Item as={Link} to={`user-profiles/${user.profile_id}`}>Perfil</NavDropdown.Item>
            {
                !user.is_admin &&
                <NavDropdown.Item as={Link} to={`user-automated-emails`}>Correos</NavDropdown.Item>
            }
            {
                !user.is_admin &&
                <NavDropdown.Item as={Link} to={`payment`} disabled={user.was_paid}>Pago</NavDropdown.Item>
            }
            <NavDropdown.Divider></NavDropdown.Divider>
            <NavDropdown.Item onClick={logout}>Salir</NavDropdown.Item>

            
        </div>
       
    )
}

const ConfigurationNavDropdownCompact = ({user, ekey, header, navigate, dispatch, select}) => {
  
    const logout = async e => {
        await uservice.logout(user, dispatch); 
        navigate('/')
    }

    return (
        <Accordion.Item eventKey={ekey}>
            <Accordion.Header >{`${header}  ${user.username}`}</Accordion.Header>
            <Accordion.Body>
                <p className="" onClick={()=>select('contact')}>
                    <Link className="navbar-brand"  to={`#`} style={{display: 'flex', alignItems: 'center'}} > 
                        <span  className="" >{'Contáctanos'}</span>
                    </Link>
                </p>
                <p className="" onClick={select}>
                    <Link className="navbar-brand"  to={`${process.env.REACT_APP_DOCUMENT_URL}`} target="_blank" style={{display: 'flex', alignItems: 'center'}} > 
                        <span  className="" >{'Documentación'}</span>
                    </Link>
                </p>
                <p className="" onClick={select}>
                    <Link className="navbar-brand" to={`user-profiles/${user.profile_id}`} style={{display: 'flex', alignItems: 'center'}} > 
                        <span  className="" >{'Perfil'}</span>
                    </Link>
                </p>
                {
                    !user.is_admin &&
                    <Fragment>
                        <p className="" onClick={select}>
                            <Link className="navbar-brand" to={`user-automated-emails`} style={{display: 'flex', alignItems: 'center'}} > 
                                <span className="" >{'Correos'}</span>
                            </Link>
                        </p>
                        <p className={`${user.was_paid? "disabled-link": ""}`} onClick={select}>
                            <Link className="navbar-brand" to={`payment`} style={{display: 'flex', alignItems: 'center'}} > 
                                <span className="" >{'Pago'}</span>
                            </Link>
                        </p>
                    </Fragment>
                
                }
                
                <p className="" onClick={logout} >
                    <Link className="navbar-brand"  to={`#`} style={{display: 'flex', alignItems: 'center'}} > 
                        <span  className="" >{'Salir'}</span>
                    </Link>
                </p>
                
                <ContactUsModal /> 
                
            </Accordion.Body>
        </Accordion.Item> 
    )
}

const ConfigurationNavDropdown = ({user, navigate, dispatch, select}) => {

    const [showDropdown, setShowDropdown] = useState(false);
   
    const handleDropdownOpen = () => {
        setShowDropdown(true);
    };
  
    const handleDropdownClose = () => {
        setShowDropdown(false);
    };

    const handleDropdownClick = () => {
        setShowDropdown(!showDropdown);
    };
 
    return (
        <NavDropdown title={<Icon path={mdiAccount} size={1}/>} className="config-menu-item-container" 
                    show={showDropdown}
                    onClick={handleDropdownClick}
                    onMouseEnter={handleDropdownOpen}
                    onMouseLeave={handleDropdownClose}
                    >
                    <ConfigurationNavDropdownBody  user={user} navigate={navigate} dispatch={dispatch} select={select}/>

        </NavDropdown>
    )
    
}

const CustomBoostrapNavDropdown = ({header, configs, select}) => {

    const [showDropdown, setShowDropdown] = useState(false);
  
    const handleDropdownOpen = () => {
        setShowDropdown(true);
    };
  
    const handleDropdownClose = () => {
        setShowDropdown(false);
    };

    const handleDropdownClick = () => {
        setShowDropdown(!showDropdown);
    };
 
    return (
        <NavDropdown title={`${header}`} className="nav-menu-item-container large-nav-link"
                                show={showDropdown}
                                onClick={handleDropdownClick}
                                onMouseEnter={handleDropdownOpen}
                                onMouseLeave={handleDropdownClose} >
                {
                    configs.map((c, index) => {

                        if(!c) {
                            return (
                                <NavDropdown.Divider key={`divider-${index}`} />
                            )
                        }
                           
                        return (
                            <div className="menu-item-container" key={index} onClick={select}>
                                <Link className="navbar-brand" to={`${c.object.basePath}`} style={{display: 'flex', alignItems: 'center', padding: '0px'}} > 
                                    <div className="d-none d-md-block"  style={{marginRight: '10px'}}>
                                        <MaterialIcon obj={c.object.name} />
                                    </div>
                                    
                                    <span  className="menu-item-submenu" style={{fontStyle: 'normal'}}>{c.object.pluralLabel}</span>
                                </Link>
                            </div>
                        )
                    })
                }
            
        </NavDropdown>
    )
    
}

const CustomBoostrapNavDropdownCompact = ({header, configs, select, ekey}) => {
 
    return (
        <Accordion.Item eventKey={ekey}>
            <Accordion.Header >{`${header}`}</Accordion.Header>
            <Accordion.Body>
                {
                    configs.map((c, index) => {

                        if (!c) {
                            return (
                                <hr key={`divider-${index}`} className="my-2" />
                            );
                        }

                        return (
                            <div className="mb-2" key={index} onClick={select} >
                                <Link className="navbar-brand" to={`${c.object.basePath}`} style={{display: 'flex', alignItems: 'center', padding: '0px'}} > 
                                    <div className=""  style={{marginRight: '10px'}}>
                                        <MaterialIcon obj={c.object.name} />
                                    </div>
                                    <span  className="" >{c.object.pluralLabel}</span>
                                </Link>
                            </div>
                        )
                    })
                }
            </Accordion.Body>
        </Accordion.Item>
    )
}
 
const AuthenticatedContainerHeader = ({user, sideMenus}) => {
     
    const [expanded, setExpanded] = useState(false);
    const [contactUsModal, setContactUsModal] = useState(false);
  
    const handleToggle = () => setExpanded(!expanded);
    const handleClose = () => setExpanded(false);

    const handleSelect = (item) => {
        setExpanded(false)

        if(item=='contact')
            setContactUsModal(true)
    }

    const navigate = useNavigate()

    const dispatch = useDispatch()
   
    return ( 
        <Navbar bg="light" expand="lg" className="custom-top-header fixed-top" expanded={expanded}>

            <div className="container-fluid">

                
                <Navbar.Brand as={Link} to="home/">
                    <div className="hstack gap-2">
                        <img src={logo} height="25" alt="logo" />
                    </div>
                </Navbar.Brand>
 
                <Navbar.Toggle style={{ padding: 3, margin: 3 }} onClick={handleToggle} className="ms-auto">
                    <Icon path={mdiMenu} size={1} color="black" style={{ width: '15px', height: '15px' }} />
                </Navbar.Toggle>
 
                {/** Menu for large devices */}
                <div className="d-lg-flex d-none align-items-center col-11">
                    <Nav className="me-auto">
                    
                        {
                            user.is_admin &&
                            <CustomBoostrapNavDropdown header={tc.NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL} configs={[
                                configs.transactionEmail,
                                null, 
                                configs.expenseEmail, 
                                configs.incomeEmail,
                                null,
                                configs.assetEmail,
                                configs.liabilityPaymentEmail
                            ]}
                            select={handleSelect}/>
                        }

                        {
                            user.is_admin &&
                            <CustomBoostrapNavDropdown header={tc.NAV_SECTION_DATA_SYSTEM_LABEL} configs={[
                                configs.reportStandard,
                                configs.chartStandard, 
                                configs.dashboardStandard, 
                                configs.reportType, 
                            ]} select={handleSelect}/>
                        }

                        {
                            <CustomBoostrapNavDropdown header={tc.NAV_SECTION_DATA_LABEL} configs={[
                                configs.report,
                                configs.chart, 
                                configs.dashboard,
                            ]} select={handleSelect}/>
                        }
                            
                        {
                            !user.is_admin &&
                            <CustomBoostrapNavDropdown header={tc.NAV_SECTION_ACCOUNTS_LABEL} configs={[
                                configs.accountExpense, 
                                configs.accountIncome,
                                null,
                                configs.accountAsset,
                                configs.accountLiability, 
                            ]} select={handleSelect}/>
                        }

                        {
                            !user.is_admin &&
                            <CustomBoostrapNavDropdown header={tc.NAV_SECTION_TRANSACTIONS_LABEL} configs={[
                                configs.expense,
                                configs.income,
                                null,
                                configs.asset,
                                configs.liabilityPayment,
                                null,
                                configs.expenseBudget
                            ]} select={handleSelect}/>
                        }

                        {
                            !user.is_admin &&
                            <CustomBoostrapNavDropdown header={tc.NAV_SECTION_TRANSACTIONS_TEMPLATES_LABEL} configs={[
                                configs.expenseTemplate,
                                configs.incomeTemplate, 
                                null,
                                configs.assetTemplate, 
                                configs.liabilityPaymentTemplate
                            ]} select={handleSelect}/>
                        }
                        
                        {
                            !user.is_admin &&
                            <CustomBoostrapNavDropdown header={tc.NAV_SECTION_THIRD_PARTY_LABEL} configs={[
                                configs.vendor, 
                                configs.buyer, 
                            ]} select={handleSelect}/>
                        }

                        {
                            !user.is_admin &&
                            <CustomBoostrapNavDropdown header={tc.NAV_SECTION_PAYMENT_METHODS_LABEL} configs={[
                                configs.paymentMethodBank, 
                                configs.paymentMethodCard, 
                                configs.paymentMethodCash,
                                configs.paymentMethodWallet, 
                            ]} select={handleSelect}/>
                        }

                        
                    </Nav>
                    <div className="ms-auto">
                        <div className="hstack gap-2">
                            <span>{user.username}</span>
                            <ConfigurationNavDropdown user={user} dispatch={dispatch} navigate={navigate} select={handleSelect}/>
                        </div>
                        
                    </div>
                </div>
                
                
            </div> 

            {/** Menu for small devices */}
            <Modal show={expanded} onHide={handleClose} dialogClassName="modal-top" fullscreen={true}>
                <Modal.Header closeButton>
                    
                </Modal.Header>
                <Modal.Body>
 
                    <Accordion >
 
                        {user.is_admin && (
                            <CustomBoostrapNavDropdownCompact header={tc.NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL} configs={[
                                configs.transactionEmail, 
                                null,
                                configs.expenseEmail, 
                                configs.incomeEmail,
                                null,
                                configs.assetEmail, 
                                configs.liabilityPaymentEmail
                            ]}
                            ekey={0}
                            select={handleSelect}></CustomBoostrapNavDropdownCompact>
                        )}

                        {user.is_admin && (
                            <CustomBoostrapNavDropdownCompact header={tc.NAV_SECTION_DATA_SYSTEM_LABEL} configs={[
                                configs.reportStandard,
                                configs.chartStandard,
                                configs.dashboardStandard, 
                                null,
                                configs.reportType,
                            ]}
                            ekey={1}
                            select={handleSelect}></CustomBoostrapNavDropdownCompact>
                        )}

                        <CustomBoostrapNavDropdownCompact header={tc.NAV_SECTION_DATA_LABEL} 
                            configs={[
                                configs.report,
                                configs.chart,
                                configs.dashboard,
                            ]}
                            ekey={2}
                            select={handleSelect}>
                        </CustomBoostrapNavDropdownCompact>

                        {!user.is_admin && (
                            <CustomBoostrapNavDropdownCompact header={tc.NAV_SECTION_ACCOUNTS_LABEL} configs={[
                                configs.accountExpense, 
                                configs.accountIncome, 
                                null,
                                configs.accountAsset,
                                configs.accountLiability, 
                            ]}
                            ekey={3}
                            select={handleSelect}></CustomBoostrapNavDropdownCompact>
                        )}

                        {!user.is_admin && (
                            <CustomBoostrapNavDropdownCompact header={tc.NAV_SECTION_TRANSACTIONS_LABEL} configs={[
                                configs.expense,
                                configs.income, 
                                null,
                                configs.asset,
                                configs.liabilityPayment,
                                null,
                                configs.expenseBudget
                            ]}
                            ekey={4}
                            select={handleSelect}></CustomBoostrapNavDropdownCompact>
                        )}

                        {!user.is_admin && (
                            <CustomBoostrapNavDropdownCompact header={tc.NAV_SECTION_TRANSACTIONS_TEMPLATES_LABEL} configs={[
                                configs.expenseTemplate,
                                configs.incomeTemplate, 
                                null,
                                configs.assetTemplate, 
                                configs.liabilityPaymentTemplate
                            ]}
                            ekey={5}
                            select={handleSelect}></CustomBoostrapNavDropdownCompact>
                        )}

                        {!user.is_admin && (
                            <CustomBoostrapNavDropdownCompact header={tc.NAV_SECTION_THIRD_PARTY_LABEL} configs={[
                                configs.vendor, 
                                configs.buyer, 
                            ]}
                            ekey={7}
                            select={handleSelect}></CustomBoostrapNavDropdownCompact>
                        )}

                        {!user.is_admin && (
                            <CustomBoostrapNavDropdownCompact header={tc.NAV_SECTION_PAYMENT_METHODS_LABEL} configs={[
                                configs.paymentMethodBank, 
                                configs.paymentMethodCard, 
                                configs.paymentMethodCash, 
                                configs.paymentMethodWallet, 
                            ]}
                            ekey={8}
                            select={handleSelect}></CustomBoostrapNavDropdownCompact>
                        )}
         
                        <ConfigurationNavDropdownCompact header={`Cuenta`}
                            user={user}
                            ekey={9}
                            select={handleSelect}
                            dispatch={dispatch}
                            navigate={navigate}></ConfigurationNavDropdownCompact>

                    </Accordion>
                </Modal.Body>
            </Modal> 

            <ContactUsModal contactUsModal={contactUsModal} setContactUsModal={setContactUsModal} />
            
        </Navbar>
    )
}
 
export default AuthenticatedContainerHeader