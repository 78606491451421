import React, { useEffect, useState } from "react"
  
import * as c from "../../configurations/constants"
import Selector from "../../selectors/selector"

import './fields.css'
import OutputField from "./output-field"
 
const InputField = ({value, vConfig, user, onChange, isValid, action}) => {
    
    if((action == 'edit' && !vConfig.isEditable)) {
        return (
            <div className="border-bottom py-2">
                <OutputField value={value} vConfig={vConfig} user={user} />
            </div>
        )
    }
        
    switch (vConfig.type) {

        case c.OBJECT_FIELD_DATA_TYPE_NUMBER:
            return NumberFormatted({value, vConfig, user, onChange, isValid})

        case c.OBJECT_FIELD_DATA_TYPE_CURRENCY:
            return CurrencyFormatted({value, vConfig, user, onChange, isValid})

        case c.OBJECT_FIELD_DATA_TYPE_TEXT:
            return TextFormatted({value, vConfig, user, onChange, isValid})

        case c.OBJECT_FIELD_DATA_TYPE_TEXTAREA:
        case c.OBJECT_FIELD_DATA_TYPE_TEXTAREA_HTML:
            return TextAreaFormatted({value, vConfig, user, onChange, isValid})
    
        case c.OBJECT_FIELD_DATA_TYPE_DATE:
            return DateFormatted({value, vConfig, user, onChange, isValid})

        case c.OBJECT_FIELD_DATA_TYPE_DATETIME:
            return DatetimeFormatted({value, vConfig, user, onChange, isValid})

        case c.OBJECT_FIELD_DATA_TYPE_SELECTOR:
            return SelectorFormatted({value, vConfig, user, onChange, isValid})
        
        case c.OBJECT_FIELD_DATA_TYPE_PICKLIST:
            return PicklistFormatted({value, vConfig, user, onChange, isValid})

        case c.OBJECT_FIELD_DATA_TYPE_BOOLEAN:
            return BooleanFormatted({value, vConfig, user, onChange, isValid})
    
        default:
            break;
    }
}

const TextAreaFormatted = ({value, vConfig, user, onChange, isValid}) => {
 
    const [isElementValid, setIsElementValid] = useState(true)
 
    useEffect(()=> {
        setIsElementValid(isValid)
    }, [isValid])
    
    return (
        <textarea rows="3" className={`form-control ${isElementValid? "": "is-invalid"} ${vConfig.isRequired? "custom-field-required": ""}`} value={value} onChange={(e) => {setIsElementValid(true); onChange(e.target.value)}}/>
    )
}

const TextFormatted = ({value, vConfig, user, onChange, isValid}) => {
  
    const [isElementValid, setIsElementValid] = useState(true)
 
    useEffect(()=> {
        setIsElementValid(isValid)
    }, [isValid])

    return ( 
        <input type="text" className={`form-control ${isElementValid? "": "is-invalid"} ${vConfig.isRequired? "custom-field-required": ""}`} value={value} onChange={(e) => {setIsElementValid(true); onChange(e.target.value)}} required/>
        
    )
}

const CurrencyFormatted = ({value, vConfig, user, onChange, isValid}) => {
    const [isElementValid, setIsElementValid] = useState(true);

    useEffect(() => {
        setIsElementValid(isValid);
    }, [isValid]);

    const handleInputChange = (e) => {
        let newValue = e.target.value;
        
        // Elimina todo excepto números y punto
        newValue = newValue.replace(/[^0-9.]/g, '');
        
        // Asegura que solo haya un punto decimal
        const parts = newValue.split('.');
        if (parts.length > 2) {
            newValue = parts[0] + '.' + parts.slice(1).join('');
        }

        setIsElementValid(true);
        onChange(newValue);
    };

    return (
        <input
            type="text"
            className={`currency-format form-control ${isElementValid ? "" : "is-invalid"} ${vConfig.isRequired ? "custom-field-required" : ""}`}
            value={value}
            onChange={handleInputChange}
        />
    );
};

const NumberFormatted = ({value, vConfig, user, onChange, isValid}) => {
 
    const [isElementValid, setIsElementValid] = useState(true)
 
    useEffect(()=> {
        setIsElementValid(isValid)
    }, [isValid])

    return (
        <input type="number" className={`form-control ${isElementValid? "": "is-invalid"} ${vConfig.isRequired? "custom-field-required": ""}`} value={value} onChange={(e) => {setIsElementValid(true); onChange(e.target.value)}} />
    )
}

const DateFormatted = ({value, vConfig, user, onChange, isValid}) => {
    const getLocalISODate = (date = new Date()) => {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    };

    const [nvalue, setNvalue] = useState(value ? value.split('T')[0] : getLocalISODate());
    
    const today = getLocalISODate();
    const minDate = getLocalISODate(new Date(user.created_date));
 
    const [isElementValid, setIsElementValid] = useState(true);
  
    useEffect(() => {
        if (value) {
            const newValue = value.split('T')[0];
            setNvalue(newValue);
            onChange(newValue);
        } else {
            const defaultValue = getLocalISODate();
            setNvalue(defaultValue);
            onChange(defaultValue);
        }
    }, []);

    const handleChange = (e) => {

        setIsElementValid(true);

        const newValue = e.target.value;
         
        if (newValue !== value) {
            setNvalue(e.target.value);
            onChange(newValue);
        }
         
    };

    return (
        <input 
            type="date" 
            className={`form-control ${isElementValid ? "" : "is-invalid"} ${vConfig.isRequired ? "custom-field-required" : ""}`} 
            value={nvalue} 
            onChange={handleChange} 
            min={minDate}
            max={today}
        />
    );
}

const DatetimeFormatted = ({value, vConfig, user, onChange, isValid}) => {
 
    const [isElementValid, setIsElementValid] = useState(true)
 
    useEffect(()=> {
        setIsElementValid(isValid)
    }, [isValid])

    return (
        <input type="date" className={`form-control ${isElementValid? "": "is-invalid"} ${vConfig.isRequired? "custom-field-required": ""}`} value={value} onChange={(e) => {setIsElementValid(true); onChange(e.target.value)}} />
    )
}

const SelectorFormatted = ({value, vConfig, user, onChange, isValid}) => {
   
    const [isElementValid, setIsElementValid] = useState(true)
 
    useEffect(()=> {
        setIsElementValid(isValid)
    }, [isValid])
 
    return (
        <div className={`${vConfig.isRequired? "custom-field-required": ""}`}>
            <div className={`${isElementValid? "": "custom-selector-invalid"}`}>
                <Selector user={user} vConfig={vConfig} value={value?.id} onChange={(v) => onChange(v)}/>
            </div>
        </div>
        
    )
}

const PicklistFormatted = ({value, vConfig, user, onChange, isValid}) => {
   
    const [isElementValid, setIsElementValid] = useState(true)
 
    useEffect(()=> {
        setIsElementValid(isValid)
    }, [isValid])
    
    return (
        <select value={value.id} className={`form-select ${isElementValid? "": "is-invalid"} ${vConfig.isRequired? "custom-field-required": ""}`} aria-label="Default select example" onChange={(e) => onChange(e.target.value)}>
            <option key={-1}>---</option>
            {
                vConfig.values.map((v, i) => {
                    return <option key={i} value={v.value} >{v.label}</option>
                })
            }
        </select>

    )
}

const BooleanFormatted = ({value, vConfig, user, onChange, isValid}) => {
    return (
        <div className="form-check">
            <input className="form-check-input" checked={value} type="checkbox" onChange={() => onChange(!value)}></input> 
        </div>
       
    )
}



export default InputField