import React, { useLayoutEffect, useState, useRef, useEffect, Fragment } from "react"
import PropTypes from 'prop-types'; 
import _ from 'underscore'
import Icon from '@mdi/react';
import { mdiDotsHorizontal, mdiClose } from '@mdi/js';

import TableListBody from "./table-list-body";
import TableListCompactBody from "./table-list-compact-body";

import TableListHeader from "./table-list-header";

import TableListSearch from "./table-list-search";
import TableListViews from "./table-list-views";
import PrinterContainerModal from "../../modals/printer-container-modal";

import 'bootstrap-icons/font/bootstrap-icons.css';
 
const TableList = ({ items, configs, disableTracking, bottomHandler, loadingMore, parameters, refresh, viewId, user }) => {
     
    const bottomMargin = 30
    const [style, setStyle] = useState({height: 0});
    const [scrollPosition, setScrollPosition] = useState(0);
    const [optionsOpened, setOptionsOpened] = useState(false);

    const [showModal, setShowModal] = useState(false)
    const [elementPrinted, setElementPrinted] = useState(false)

    const printElement = (item) => {
        setElementPrinted(item)
        setShowModal(true)
    }

    const closeModal = () => {
        setElementPrinted(null)
        setShowModal(false)
    }

 
    const scrollContainer = useRef(null);

    document.title = `${configs.object.pluralLabel}`
     
    let lastPosition;
    const trackScrollMovingDown = (e) => {

        if(!lastPosition) {
            lastPosition = e.target.scrollTop
            return true
        }            

        let isMovingDown = lastPosition < e.target.scrollTop
        lastPosition = e.target.scrollTop

        return isMovingDown
    }
 
    const trackScrolling = (e, parameters) => {
   
        if(disableTracking || loadingMore || !bottomHandler)
            return 
        
        // direction of scroll
        if(!trackScrollMovingDown(e))    
            return
        
        const scrollContainerBottomPosition = Math.round(e.target.scrollTop + e.target.clientHeight);
        const scrollPosition = Math.round(e.target.scrollHeight - 1);  
        if (scrollPosition > scrollContainerBottomPosition)
            return
         
        setScrollPosition(scrollContainer.current.scrollTop)
        bottomHandler(parameters)
    }
 
    useLayoutEffect(() => {

        const BOTTON_MARGIN = bottomMargin? bottomMargin: 0;
         
        const updateWindowDimentions = () => {
            setStyle({height: window.innerHeight - scrollContainer.current.getBoundingClientRect().top - BOTTON_MARGIN}) // 
        }

        const scrollThrottledEvent =  _.throttle((e)=> trackScrolling(e, parameters), 1000);
        
        window.addEventListener('resize', updateWindowDimentions);
        scrollContainer.current.addEventListener('scroll', scrollThrottledEvent)

        updateWindowDimentions()

        scrollContainer.current.scrollTop = scrollPosition
  
        return function clearup() {

            if(!window.removeEventListener)
                return

            window.removeEventListener('resize', updateWindowDimentions)
            scrollContainer.current.removeEventListener('scroll', scrollThrottledEvent)
            
        }
    }, [bottomMargin, parameters, items]);

    useEffect(()=> {
        setScrollPosition(0)

    }, [viewId])
      
    return (
        <div className="vstack gap-2">

            {/**  header md */}
            <div className="row d-none d-md-flex">
                <div className="col-md-6 mb-2">
                    {
                        configs.actions.listviews.enable && 
                        <TableListViews configs={configs} refresh={refresh} viewId={viewId}/>
                    }
                </div>
               
                <div className="col-md-6 mb-2">
                    {
                        configs.actions.search.enable && 
                        <TableListSearch configs={configs} viewId={viewId}/>
                    }
                </div>
            </div> 

            {/**  header sm */}
            <div className="row d-sm-block d-md-none gap-2">
                
                {
                    optionsOpened &&
                    <div className="ms-auto">
                        <Icon path={mdiClose} className="icon-option-primary" onClick={()=> setOptionsOpened(false)} />
                    </div>
                }

                { 
                    <div className="col-md-6 mb-2" style={{ display: optionsOpened ? 'block' : 'none' }}>
                        {
                            configs.actions.listviews.enable && 
                            <TableListViews configs={configs} refresh={refresh} viewId={viewId}/>
                        }
                    </div>
                }

                <div className="d-flex align-items-center mb-2">
                    {!optionsOpened && (
                        <div className="flex-shrink-0 me-2">
                            <Icon 
                                path={mdiDotsHorizontal} 
                                className="icon-option-primary" 
                                onClick={() => setOptionsOpened(true)} 
                            />
                        </div>
                    )}
                    {configs.actions.search.enable && 
                        <div className="flex-grow-1">
                            <TableListSearch configs={configs} viewId={viewId}/>
                        </div>
                    }
                </div>
            </div>
            
            
            <div className="">

                <div className="container-fluid d-none d-md-block">
                    <TableListHeader configs={configs} user={user}/>
                </div>
                
                {/* normal layout */}
                <div className="container-fluid overflow-scroll table-scroll-removed " style={style} ref={scrollContainer}>
                    <div className="d-none d-md-block">
                        <TableListBody items={items} configs={configs} loadingMore={loadingMore}  printElement={printElement}/>
                    </div>
                    <div className="d-block d-md-none" style={style}>
                        <TableListCompactBody items={items} configs={configs} loadingMore={loadingMore}  printElement={printElement}/>
                    </div>
                </div>
            </div>

            {
                showModal && elementPrinted &&
                <PrinterContainerModal close={closeModal} element={elementPrinted} configs={configs}/>
            }
 
        </div>
    )
}
 
export default TableList