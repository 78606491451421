import React, { useState, useEffect, useContext, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux'
  
import TableList from "../table";
  
import UserContext from '../../../../user/user-context';

// if you change this value you must change in the server too
// generic-route
const DATA_LIMIT = 50 

const TableContainerPage = ({configs, service}) => {
     
    const user = useContext(UserContext)

    const list = useSelector((state)=> state[configs.object.sliceName].list) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)
      
    // identify when render of the component should start
    const [fetched, setFetched] = useState(false)
     
    const [text, setText] = useState(false)
    // const [lengthChunk, setLengthChunk] = useState(false)
    const [viewId, setViewId] = useState('')
    const [refreshTime, setRefreshTime] = useState(new Date())
 
    const [disableTracking, setDisableTracking] = useState(false)
 
    const dispatch = useDispatch()

    const searchHandler = (text) => {
        service.getAll({payload: { text, viewId }, user}, dispatch)
        setDisableTracking(false)
        setText(text)
    }

    const listviewsHandler = (lvid) => {

        if(!configs.actions.listviews.enable)
            return 

        setViewId(lvid)
        setRefreshTime(new Date()) 
    }
    
    const scrollBottomHandler = async (parameters) => {
    
        if(loading)
            return
       
        if(!parameters.offset || parameters.offset === 0)
            return
 
        const response = await service.getMore({payload: {offset: parameters.offset, text, viewId}, user}, dispatch)
        if(response.success)
            setDisableTracking(response.data.length < DATA_LIMIT)
    }
 
    useEffect( () => {
 
        const load = async () => {
            
            // fetched
            setFetched(true)
 
            if(configs.actions.listviews.enable && !viewId)
                return
  
            service.getAll({payload: { text, viewId }, user }, dispatch)
        }
 
        load()
        setDisableTracking(false)

    }, [text, viewId, refreshTime])

    configs.actions.search.handler = searchHandler
    configs.actions.listviews.handler = listviewsHandler

    return (
        <Fragment>
            {
                fetched &&
                <TableList 
                    viewId={viewId} 
                    // refresh={refreshHandler} 
                    items={list} 
                    configs={configs} 
                    bottomHandler={scrollBottomHandler} 
                    disableTracking={disableTracking} 
                    loadingMore={loading} 
                    parameters={{offset: list.length}}
                    user={user}/>
            }
        </Fragment>
    )
}

export default TableContainerPage