import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
  
import ChartEditorColumnSelector from "./chart-editor-column-selector";
import * as reportService from '../../../../utilities/services/report-standard-service'
import { refreshProperties } from '../../../../utilities/redux/actions/chart-slice';
import Selector from "../../../../common/selectors/selector";
import * as c from "../../../../common/configurations/constants";
import * as tc from "../../../../common/configurations/text-constants";
import * as setValues from "../../../../common/configurations/set-constants"

const ChartEditorProperties = ({ chart, user }) => {

    const [report, setReport] = useState({ name: '', columns: []})
    const [label, setLabel] = useState(chart.label_column_id)
    const [value, setValue] = useState(chart.value_column_id)
    const [value2, setValue2] = useState(chart.value2_column_id)
    const [type, setType] = useState(chart.chart_type_id)
  
    const [labelColumns, setLabelColumns] = useState([])
    const [valueColumns, setValueColumns] = useState([])
 
    const dispatch = useDispatch()

    const getCurrentChart = () => {
        return {label, value, value2, type}
    }
    const onChangeType = (v) => {
        setType(v)
        updates({...getCurrentChart(), type: v})
    }

    const onChangeLabel = (v) => {
        setLabel(v?.id)
        updates({...getCurrentChart(), label: v?.id})
    }

    const onChangeValue = (v) => {
        setValue(v?.id)
        updates({...getCurrentChart(), value: v?.id})
    }

    const onChangeValue2 = (v) => {
        setValue2(v?.id)
        
        updates({...getCurrentChart(), value2: v?.id})
    }

    const updates = (properties) => { 
        dispatch(refreshProperties({label: properties.label, value: properties.value, value2: properties.value2, type: properties.type}))
    }

    useEffect(() => {
        // load item
        const load = async () => {
  
            const response = await reportService.get({payload: {id: chart.report_source_id}, user}, dispatch)
            if(response.success) {
                const report = response.data
                setReport(report) 
  
                setLabelColumns(report.columns.filter(x => setValues.CHAR_EDITOR_LABEL_DATATYPE.includes(x.data_type)))
                setValueColumns(report.columns.filter(x => setValues.CHAR_EDITOR_VALUE_DATATYPE.includes(x.data_type)))
     
                dispatch(refreshProperties({label: label, value: value, value2: value2, type: type}))
            }
            
        }

        load()
    }, [])

    return (
        <div>
            
            <div className="vstack gap-3">

                <div>
                    <label className="form-label">{tc.OBJECT_FIELD_CHART_REPORT_SOURCE}</label>
                    <input className="form-control" value={report.name} disabled/>
                </div>
                <div>
                    <label className="form-label">{tc.OBJECT_FIELD_CHART_TYPE}</label>
                    <Selector user={user} value={type} vConfig={{context: c.SELECTOR_CONTEXT_CHART_TYPE}} onChange={onChangeType} />
                </div>

                <div>
                    <label>{tc.OBJECT_FIELD_CHART_SETUP_LABEL}</label>
                    
                    {
                        labelColumns && <ChartEditorColumnSelector columns={labelColumns} onChange={onChangeLabel} user={user} leaf={labelColumns.filter(x => x.id ===label)[0]}/>
                    }
                    
                </div>
                <div>
                    <label>{tc.OBJECT_FIELD_CHART_SETUP_VALUE_1}</label>
                    {
                        valueColumns && <ChartEditorColumnSelector columns={valueColumns} onChange={onChangeValue} user={user} leaf={valueColumns.filter(x => x.id ===value)[0]}/>
                    }
                </div> 
                <div>
                    <label>{tc.OBJECT_FIELD_CHART_SETUP_VALUE_2}</label>
                    {
                        valueColumns && <ChartEditorColumnSelector columns={valueColumns} onChange={onChangeValue2} user={user} leaf={valueColumns.filter(x => x.id ===value2)[0]}/>
                    }
                </div> 
                
            </div>

        </div>
    )
}

export default ChartEditorProperties