import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPage from "../../common/layouts/pages/detail-page";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/support-ticket-service'
import * as sm from '../../utilities/services/support-ticket-message-service'
import * as tc from "../../common/configurations/text-constants";
import * as c from "../../common/configurations/constants";
 
import OutputField from "../../common/layouts/fields/output-field";

const TicketMessage = ({item, user}) => {
  
    return (
        <div>
            <div className="hstack gap-1">
                {
                    item.is_support &&
                    <span className="badge bg-danger">
                        <i className="bi bi-file-earmark-person"></i>
                    </span>
                }
                <div className="badge bg-secondary">
                    <OutputField value={item.created_date} vConfig={{type: c.OBJECT_FIELD_DATA_TYPE_DATETIME}} user={user}/>
                </div>
            </div>
           
            <div className="">
                <p style={{whiteSpace:"pre-wrap"}}>
                    {item.tmessage}
                </p>
            </div>

            <br />
        </div>
    )
}

const EmptyContainerTicketMessages = () => {

    return (
        <p>
            No hay mensajes
        </p>
    )
}

const TicketDetailMessagesPage = () => {

    const { id } = useParams()

    const navigate = useNavigate()
    const user = useContext(UserContext)

    const [list, setList] = useState([])
    const [message, setMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    
    const dispatch = useDispatch()

    const addMessage = async () => {
        
        const response = await sm.add({payload: {tmessage: message, support_ticket_id: id}, user}, dispatch)

        if(response.success)
            setList([response.data, ...list])
    
        setMessage('')
    }
   
    useEffect(() => {

        const load = async () => {
            const response = await sm.getAll({payload: {id}, user }, dispatch)
            if(response.success)
                setList(response.data) 
        }
        
        load()
    }, [])

    return (
        <div className="card">
            <div className="card-body"> 
                {
                    list.length === 0 &&
                    <EmptyContainerTicketMessages />
                }
                {
                    list.length > 0 && 
                    <div>
                        <span>Ultimos Mensajes</span>
                        <hr/>

                        <TicketMessage item={list[0]} user={user}/>
                        {
                            list.length > 1 &&
                            <TicketMessage item={list[1]} user={user}/>
                        }
                       
                    </div>
                }

                <div onClick={()=> setShowModal(true)}>
                    <a href="#">Nuevo Mensaje / Todos los Mensajes</a>
                </div>
            
                <Modal show={showModal} onHide={()=> setShowModal(false)} fullscreen={true}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>        
                    <Modal.Body>
                        <div className="vstack gap-2">

                            <textarea rows={5} className="form-control" style={{width: '100%'}} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>

                            <button type="button" className="btn btn-primary" style={{width: '100px'}} onClick={addMessage}>{tc.BUTTON_SAVE_LABEL}</button>

                            <hr />

                            <div  style={{height: '500px', overflowY: 'auto'}}>
                                {
                                    list.map((m, i) => {
                                        return (
                                            <div key={i}>
                                                <TicketMessage item={m} />
                                            </div>
                                        )
                                    })
                                }

                                {
                                    list.length === 0 &&
                                    <EmptyContainerTicketMessages />
                                }
                            </div>
                        </div>
                         
                    </Modal.Body>
                
                    <Modal.Footer>
                        <button className="btn btn-secondary btn-sm" onClick={()=> setShowModal(false)}>{tc.BUTTON_CLOSE_LABEL}</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default TicketDetailMessagesPage