import configs from './configuration-container'
import * as aservice from './services/account-service'
import * as c from '../common/configurations/constants'

const getById = async (id, user, dispatch) => {

    if(!id)
        return ''

    // get 3 first letters
    const pref = id.substring(0, 3)

    switch(pref) {
        // case 'vzw':
        //     return configs.liability.object.basePath
        case '1q8':
            return configs.asset.object.basePath
        case 'd3o':
            return configs.expense.object.basePath
        case 'x3h':
            return configs.income.object.basePath
        case '5my':
            return configs.liabilityPayment.object.basePath
        
        case 'sn9':
            return configs.expenseTemplate.object.basePath
        case 'n8j':
            return configs.incomeTemplate.object.basePath
        case '3c0':
            return configs.assetTemplate.object.basePath
        case 'cld':
            return configs.liabilityPaymentTemplate.object.basePath
        // case 'fb7':
        //     return configs.liabilityTemplate.object.basePath
       

        case 'lq6':
            return configs.chart.object.basePath
        case 'u9d':
            return configs.report.object.basePath
        case 'u7q':
            return configs.dashboard.object.basePath
        case 'lqs':
            return configs.chartStandard.object.basePath
        case 'u7s':
            return configs.dashboardStandard.object.basePath
        case 'u9s':
            return configs.reportStandard.object.basePath
        case 'mm2':
            return configs.reportType.object.basePath
       

        case 'jpp':
            return configs.expenseBudget.object.basePath

        case 'tv0':
            return configs.vendor.object.basePath
        case 'tb0':
            return configs.buyer.object.basePath

            
        case 'bxc':
            return configs.expenseEmail.object.basePath
        case 'imb':
            return configs.incomeEmail.object.basePath
        case 'vx6':
            return configs.assetEmail.object.basePath
        case 'd1c':
            return configs.liabilityPaymentEmail.object.basePath
        case 'rny':
            return configs.transactionEmail.object.basePath
        case 'ae1':
            return configs.userEmail.object.basePath
       
       
        case 'k1l':
            return configs.profile.object.basePath
        case 'stb':
            return configs.ticket.object.basePath

        case 'ga8':
            // call for record
            const response = await aservice.get({payload: {id}, user})
            if(!response.success)
                return ''
             
            const record = response.data

            // payment method validation
            switch(record.payment_method_def_name) {
                case c.ACCOUNT_DEFINITION_BANK:
                    return configs.paymentMethodBank.object.basePath
                case c.ACCOUNT_DEFINITION_CASH:
                    return configs.paymentMethodCash.object.basePath
                case c.ACCOUNT_DEFINITION_CARD:
                    return configs.paymentMethodCard.object.basePath
                case c.ACCOUNT_DEFINITION_WALLET:
                    return configs.paymentMethodWallet.object.basePath
                default:
                    break
            }
            
            // get path from root type
            switch(record.account_root_name) {
                case 'Expenses':
                    return configs.accountExpense.object.basePath
                case 'Incomes':
                    return configs.accountIncome.object.basePath
                case 'Assets':
                    return configs.accountAsset.object.basePath
                case 'Liabilities':
                    return configs.accountLiability.object.basePath
                default:
                    return ''
            }

        default:
            return ''
    }
}

export default {
    getById
}