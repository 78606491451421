import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"

import reportConfig from '../report-standard/configs'

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        setup: {
            enable: true,
            handler: function() { console.log('search')}
        },
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'chart_type_id',
                        label: tc.OBJECT_FIELD_CHART_TYPE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_CHART_TYPE,
                        isRequired: true,
                        isEditable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'report_source_id',
                        label: tc.OBJECT_FIELD_CHART_REPORT_SOURCE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_REPORT_STANDARD,
                        isSelectorLinked: true, //reportConfig.object.basePath,
                        isRequired: true,
                        isEditable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'legend_display',
                        label: tc.OBJECT_FIELD_CHART_DISPLAY,
                        type: c.OBJECT_FIELD_DATA_TYPE_BOOLEAN,
                        isRequired: false,
                        isEditable: true,
                    },
                    {
                        ...elementTemplate,
                        name: 'legend_position',
                        label: tc.OBJECT_FIELD_CHART_POSITION,
                        type: c.OBJECT_FIELD_DATA_TYPE_PICKLIST,
                        values: [
                            {value: 'top', label: 'Arriba'},
                            {value: 'bottom', label: 'Abajo'},
                            {value: 'right', label: 'Derecha'},
                            {value: 'left', label: 'Izquierda'},
                        ],
                        isRequired: false,
                        isEditable: true
                        
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-graph-up-arrow'
    },

    object: {
        name: 'chart_standard',
        label: tc.OBJECT_CHART_STANDARD_LABEL,
        pluralLabel: tc.OBJECT_CHART_STANDARD_PLURAL_LABEL,
        basePath: 'standard-charts',
        sliceName: 'chart',
        menuLabel: tc.NAV_MENU_DATA_CHART_STANDARD_LABEL,
        printable: true
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'chart_type',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_CHART_TYPE,
                isLinked: false
            },
            {
                name: 'report_source',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_CHART_REPORT_SOURCE,
                isLinked: false,
                compactViewLeftDetail: true,
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
        ]
    } 
}
 
export default configs