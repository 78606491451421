import React, { Fragment } from "react";

import OutputField from "../../fields/output-field";
import { getFormOutputField, getFieldLayout } from "../../fields/utility-field";
import DetailPageRelatedList from './detail-page-related-list'

import '../page.css'

const DetailPageBody = ({user, element, configs}) => {
 
    const getColumn = (e, ncols, index) => {

        return (
            getFormOutputField({element, user, configs: e, ncolumns: ncols, index, fieldRecordMetadata: {configs, element}, Field: OutputField})
        )
    }
    
    return (
        <div className="vstack gap-2 page-not-edit-container">
            <div className="card">
                <div className="card-body"> 
                    {getFieldLayout(configs, getColumn, element)}
                </div>
            </div>

            {
                element && configs.relatedlist && 
                <DetailPageRelatedList element={element} configs={configs} user={user} />
            } 
        </div>
        
    )

    
}

export default DetailPageBody