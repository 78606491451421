import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"

 
import incomeAccountConfig from "../../accounts/account-income/configs"
import assetAccountConfig from "../../accounts/account-asset/configs" 

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,
        
        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        schedule: {
            enable: true,
            handler: function() { console.log('schedule')}
        },
        addTemplateEntry: {
            enable: true,
            handler: function() { console.log('addTemplateEntry')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'net_income_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME_ORIGiNAL,
                        isRequired: true
                    },
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'currency_id',
                        label: tc.OBJECT_FIELD_PROFILE_CURRENCY,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_USER_CURRENCY,
                        isSelectorLinked: false,
                        isMultiCurrency: true,
                        isRequired: false
                    },
                    {
                        ...elementTemplate,
                        isVisible: false
                    },
                    
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'income_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_INCOME_INCOME,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_INCOME_LEAF_ACCOUNT,
                        isSelectorLinked: true, //incomeAccountConfig.object.basePath,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'target_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_INCOME_TARGET,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_INCOME_RECEIVER,
                        isSelectorLinked: true,
                        isRequired: true
                    },
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'buyer_id',
                        label: tc.OBJECT_BUYER_LABEL,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_BUYER,
                        isSelectorLinked: true,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-cash-coin template',
        color: cc.INCOME_TEMPLATE
    },

    object: {
        name: 'transaction_income_template',
        label: tc.OBJECT_TRANSACTION_TEMPLATE_INCOME_NAME_LABEL,
        pluralLabel: tc.OBJECT_TRANSACTION_TEMPLATE_INCOME_PLURAL_LABEL,
        basePath: 'income-templates',
        sliceName: 'incomeTemplate',
        menuLabel: tc.NAV_MENU_TRANSACTION_TEMPLATE_INCOME_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: 'text',
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'income',
                type: 'text',
                label: tc.OBJECT_FIELD_TRANSACTION_INCOME_INCOME,
                isLinked: false
            },
            {
                name: 'target',
                type: 'text',
                label: tc.OBJECT_FIELD_TRANSACTION_INCOME_TARGET,
                isLinked: false,
                compactViewLeftDetail: true
            },
            {
                name: 'amount',
                type: 'currency',
                label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                isLinked: false
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    } 
}
 
export default configs