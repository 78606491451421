import React from "react"

import * as tc from '../../configurations/text-constants'

const NotAvailableComponent = ({configs}) => {

    return (
        <div>
            {tc.COMPONENT_NOT_AVAILABLE_LABEL}
        </div>
    )
}

export default NotAvailableComponent