import React from "react";
import { Link } from "react-router-dom";
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button 
} from '@mui/material';
import { 
  CreditCard as CreditCardIcon, 
  Email as EmailIcon, 
  Person as PersonIcon 
} from '@mui/icons-material';

import './home-page.css';

const HomeDefaultPage = ({ user }) => {
    return (
        <Container className="home-default-container py-5">
            <h3>Bienvenido a DragonsCash!</h3>
            <p>¡Nos alegra tenerte aquí! Es recomendable leer los siguientes pasos para aprovechar al máximo todas las funciones de nuestra aplicación.</p>
            
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <h5>Paso 1: Métodos de Pago</h5>

                            <CreditCardIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                           
                                <p>Es obligatorio que todas tus operaciones de gastos tengan asociada una forma de pago. Para facilitar esto, el sistema crea automáticamente un monedero por defecto en la categoría 'Carteras de Efectivo'. Este monedero te permite gestionar tu dinero en efectivo, como el que llevas en la billetera o guardas en casa.</p>
        
                                <p>Además de las Carteras de Efectivo, puedes crear y gestionar otras formas de pago, incluyendo:</p>
                                
                                <ul>
                                    <li>Cuentas bancarias</li>
                                    <li>Tarjetas de crédito</li>
                                    <li>Billeteras digitales</li>
                                </ul>
                                
                                <p>Esta variedad de opciones te permite organizar tus finanzas de manera más detallada y eficiente, adaptándose a tus necesidades específicas de gestión financiera.</p>
                                    
                                <strong>¿Dónde crear nuevos métodos de pago?</strong>
                                <p>Puedes crear y gestionar todos tus métodos de pago desde el menú "Métodos de Pago" en la aplicación. Allí encontrarás opciones para añadir nuevas cuentas, tarjetas o carteras, así como para modificar las existentes.</p>
                                 
                            
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <h5>Paso 2: Transacciones Automáticas</h5>
                          
                            <EmailIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                             
                            <p><strong>¿Quieres que tus transacciones se registren automáticamente? Tenemos la solución para ti.</strong></p>
                            
                            
                            <strong>Cómo funciona:</strong>
                            <ul>
                                <li>Utilizamos los correos electrónicos que recibes de tu banco cuando realizas una transacción.</li>
                                <li>Nuestro sistema procesa estos correos para registrar automáticamente las transacciones.</li>
                                <li>Es compatible con cualquier banco que envíe notificaciones por correo electrónico.</li>
                            </ul>
                            
                            <strong>Ventajas:</strong>
                            <ul>
                                <li>Ahorro de tiempo: No necesitas ingresar manualmente cada transacción.</li>
                                <li>Precisión: Reduce errores de entrada manual.</li>
                                <li>Flexibilidad: Funciona con múltiples bancos.</li>
                            </ul>
                            
                            <p><strong>Nota importante:</strong> Para configurar esta función y aprovechar al máximo sus beneficios, te recomendamos consultar nuestra documentación detallada.</p>
                             
                            <a href={process.env.REACT_APP_DOCUMENT_URL} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">
                                Ir a Documentación
                            </a>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <h5>Paso 3: Personaliza tu Perfil</h5>
                            
                            <PersonIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                             
                            <p>Puedes personalizar la vista inicial de la aplicación configurando un panel por defecto. Este panel puede contener los gráficos y tablas que más utilizas, permitiéndote acceder rápidamente a la información más relevante para ti. En lugar de ver los pasos iniciales habituales al ingresar, la aplicación mostrará automáticamente el panel que hayas seleccionado como preferido. Esta configuración, junto con tu preferencia de moneda para la función multimoneda, se puede ajustar desde tu perfil de usuario.</p>
                             
                            <Link to={`/user-profiles/${user.profile_id}`} className="btn btn-primary btn-sm">
                                Perfil de Usuario
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <footer className="mt-5 text-center">
                Si necesitas ayuda, visita nuestra <a target="_blank" href="https://docs.google.com/document/d/188nFikulO4-pw7ycDO_JCb6DN3hDCcBU1H46QwN-8FA">página de ayuda</a>.
            </footer>

            <div className="watermark"></div>
        </Container>
    );
}

export default HomeDefaultPage;