import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux' 
import { Modal } from 'react-bootstrap'

import * as c from "../configurations/constants"
import * as tc from "../configurations/text-constants"

import * as s from '../../utilities/services/transaction-receipt-service'
import { Link } from "react-router-dom";
  
import receiptFileConfigs from '../../transactions/transaction-receipt-file/configs'
 
function PDFViewer({ url }) {
    return (
      <div style={{ width: '100%', height: '800px' }}>
        <iframe
          src={`${url}#view=fit&toolbar=0&navpanes=0`}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </div>
    );
}
  
const ReceiptRelatedList = ({econfig, transaction, parameters, user}) => {
     
    const [uploading, setUploading] = useState(false) 
    const [showModal, setShowModal] = useState(false) 
    const [itemToShow, setItemToShow] = useState() 
    
    const [list, setList] = useState([])
    
    const dispatch = useDispatch()

    const showFileModal = (item) => {
        setItemToShow(item)
        setShowModal(true)
    }

    const uploadHandler = async (file) => {
 
        if(!file) {
            setUploading(false)
            return
        }
        
        const response = await s.add({payload: file, transaction: {id: transaction.id, type: parameters.type}, user}, dispatch)
        if(response.success) {
            setList([...list, response.data])
        }

        setUploading(false)
    }

    const removeFile = async(id, index) => {
        // id of file
        const response = await s.remove({payload: {id}, type: parameters.type, user}, dispatch)
        if(response.success) {
            list.splice(index, 1)
            setList([...list])
            setUploading(false)
        } 
    }
    
    const changeHandler = async (e) => {

        setUploading(true) 
        const file = e.target.files[0]
        uploadHandler(file)
    }
 
    useEffect(() => {

        // look for transations
        const load = async () => {
            const response = await s.getAll({payload: transaction, type: parameters.type, user}, dispatch) 
            if(response.success)
                setList(response.data) 
        }

        load()

    }, [transaction])
  
    return (
        <div className="vstack gap-2">

            <span className="fst-italic">{tc.NOTE_INFO_LIMIT_OF_TRANSACTION_RECEIPTS}</span>
             
            {
                /** there is the drag&drop section  <img src={`data:image/png;base64, ${r.data}`} className="img-thumbnail"  alt="receipt"/>*/
               
                list.length < 3 && 
                 
                <form>
                    <div className="hstack gap-3">
                        
                        {
                            !uploading && 
                            <label htmlFor="receipt" className="btn btn-sm btn-primary">{tc.OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_SELECT}</label>
                        }
                        {
                            uploading && 
                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        <input id="receipt" style={{visibility:"hidden"}} type="file" name="receipt" onChange={changeHandler} />
                    </div>
                    
                </form>
                
            } 

            <ul className="list-group">
            { 
                list.map((r, i) => { 
                     
                    return ( 
                        <li className="list-group-item d-flex justify-content-between align-items-center" key={i}>
                            <Link to="#" onClick={()=>showFileModal(r)} >{r.name}</Link>
                            <button className="btn btn-danger btn-sm ms-auto" type="submit" onClick={()=> { removeFile (r.id, i)}}>{tc.BUTTON_REMOVE_LABEL}</button>
                        </li>
                    ) 
                }) 
            }
            </ul>

            {
                itemToShow &&
                <Modal show={showModal} onHide={()=>setShowModal(false)} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{itemToShow.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0 d-flex flex-column">
                        {
                            itemToShow.content_type.indexOf('pdf') != -1 ?
                            <PDFViewer url={`${process.env.REACT_APP_SERVER_URL}/${receiptFileConfigs.object.basePath}/${itemToShow.id}`} />:
                            <object 
                                data={`${process.env.REACT_APP_SERVER_URL}/${receiptFileConfigs.object.basePath}/${itemToShow.id}`} 
                                type={itemToShow.content_type}  
                                height="100%" 
                                width="100%"
                            >
                            </object>
                        }
                    </Modal.Body>
                </Modal>
            }
            
 
        </div>
    )
}

export default ReceiptRelatedList