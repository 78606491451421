import React from "react";
 
import AuthenticatedContainerBody from './authenticated-container-body'
import AuthenticatedContainerHeader from './authenticated-container-header'
import AuthenticatedContainerSideHeader from './authenticated-container-side-header'
import AuthenticatedContainerCompactSideHeader from "./authenticated-container-compact-side-header";
import configs from '../../../utilities/configuration-container'

import './authenticated-container.css'

const AuthenticatedContainer = ({user}) => {
    
    const sideMenus = user.is_admin? [
        configs.transactionEmail,
        configs.dashboardStandard,
        configs.reportStandard,
        configs.chartStandard,
        configs.reportType,
       
    ]: [
        configs.accountExpense,
        configs.expense,
        configs.expenseTemplate,
        configs.dashboard,
        configs.report,
    ]

    return (
        <section className="container-fuild authenticated-container">
             
            <AuthenticatedContainerHeader user={user} sideMenus={sideMenus}/>
  
            <div className="row-container row">
                <div className="col-md-auto bg-light side-header-container d-none d-md-block">
                    <AuthenticatedContainerSideHeader user={user} configs={sideMenus} />
                </div>
                <div className="body-container col">
                    <AuthenticatedContainerBody user={user} />
                </div>
            </div>
            <div  className="footer-supporter d-block d-md-none">

            </div>
            <footer className="main-menu d-block d-md-none">
                 <AuthenticatedContainerCompactSideHeader user={user} configs={sideMenus} />
            </footer>
        </section>
    )
}

export default AuthenticatedContainer