import React, { useState } from 'react';
import { Container, Row, Col, Button, OverlayTrigger, Popover, Nav, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Icon from '@mdi/react';
import { 
    mdiFaceAgent,
    mdiEmail,
    mdiPhone,
    mdiMenu,
    mdiAccount
} from '@mdi/js';
import * as tc from '../../common/configurations/text-constants'

import 'bootstrap/dist/css/bootstrap.min.css';
import './landing-page.css';

import screenshot1 from './images/img1.png'
import screenshot2 from './images/img2.png'
  
const ContactUsModal = ({contactUsModal, setContactUsModal}) => {
   
    return (
        <Modal show={contactUsModal} onHide={() => setContactUsModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="hstack gap-3">
                        <Icon path={mdiFaceAgent} size={1} />
                        Contáctanos
                    </div>
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                <div>
                
                    <p>¡Hola! ¿Necesitas ayuda? Estamos aquí para asistirte. Puedes comunicarte con nuestro equipo de soporte de las siguientes maneras:</p>
                    
                    <ol style={{listStyle: 'none'}}>
                        <li style={{paddingBottom: '20px'}}><strong><Icon path={mdiEmail} size={1} /></strong> Si prefieres comunicarte por correo electrónico, envíanos un mensaje a <a href="mailto:soporte@dragonscash.com">soporte@dragonscash.com</a> y te responderemos lo antes posible.</li>
                        <li><strong><Icon path={mdiPhone} size={1} /></strong> ¿Prefieres hablar con alguien? Llámanos o escribenos por whatsapp al <a href="tel:+573134536165">+573134536165</a> y estaremos encantados de ayudarte con cualquier consulta que tengas.</li>
                    </ol>

                    <p>¡No dudes en ponerte en contacto con nosotros! Estamos aquí para asegurarnos de que tengas la mejor experiencia posible.</p>
                </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={() => setContactUsModal(false)}>
                    {tc.BUTTON_CLOSE_LABEL}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const visionPopover = (
    <Popover id="vision-popover" className="custom-popover">
        <Popover.Body>
            <p>
                Dar una visión clara y completa de tus finanzas personales, empoderándote para tomar decisiones financieras inteligentes y seguras.
            </p>
        </Popover.Body>
    </Popover>
);

const precioPopover = (
    <Popover id="precio-popover" className="custom-popover">
        <Popover.Body>
            <p>
                Disfruta de un mes gratis de prueba. Posteriormente, el costo anual es de 19,90 dólares.
            </p>
            <ul>
                <li>Acceso ilimitado a todas las funciones</li>
            </ul>
        </Popover.Body>
    </Popover>
);


function LandingPage() {
  
    const [contactUsModal, setContactUsModal] = useState(false);

    return (
        <div className="App">

            <ContactUsModal contactUsModal={contactUsModal} setContactUsModal={setContactUsModal} />

            <div className="watermark"></div>
            <header className="header">
                <Container>
                    <h1 style={{fontWeight: 'bold'}}>DragonsCash</h1>
                </Container>
            </header>
        
            <div className="buttons-menus ">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="menu d-flex justify-content-start">
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={visionPopover}>
                                <Button variant="link" className="menu-link">Visión</Button>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={precioPopover}>
                                <Button variant="link" className="menu-link">Precio</Button>
                            </OverlayTrigger>
                            <Nav.Link as={Link} to={process.env.REACT_APP_DOCUMENT_URL} target='_blank'>
                                <Button variant="link" className="menu-link">Documentación</Button>
                            </Nav.Link>
                            <Button variant="link" className="menu-link" onClick={()=>setContactUsModal(true)}>Contáctanos</Button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3 mt-sm-0 d-flex justify-content-end">
                        <Nav.Link as={Link} to="/login">
                            <Button variant="primary" className="me-2">Entrar</Button>
                        </Nav.Link>
                        <Nav.Link as={Link} to="/signup">
                            <Button variant="secondary">Registrarse</Button>
                        </Nav.Link>
                    </div>
                </div>
            </div>
            
            <Container fluid className="my-5">
                <div className='row'>
                    <div className="col-12 text-center">
                        <h1 className='text-uppercase mb-3' style={{ color: '#287233', fontWeight: 'bold' }}>
                            ¡Revoluciona tu futuro financiero ahora!
                        </h1>
                        <h2 className='mb-4' style={{ color: '#2C3E50', fontStyle: 'italic' }}>
                            Tu camino hacia la libertad y el éxito económico comienza aquí
                        </h2>
                        <div className='offer-box p-4 mb-4 d-inline-block' style={{ backgroundColor: '#F8F9F9', borderRadius: '10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                            <p className='lead mb-0' style={{ color: '#34495E', fontSize: '1.25rem' }}>
                                <span className='highlight' style={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>Oferta Anual Exclusiva</span><br />
                                <strong>Prueba gratis por 30 días</strong><br />
                                Luego solo <strong style={{ color: '#287233', fontWeight: 'bold' }}>$19.90 USD al año</strong><br />
                                <span style={{ color: '#2C3E50', fontWeight: 'bold' }}>¡Menos de $2 al mes para alcanzar tu libertad financiera!</span>
                            </p>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg={6} className="features px-4 mb-5">
                        <div className="feature">
                            <h3>Generación automática de tus Transacciones Bancarias</h3>
                        </div>
                        <div className="feature">
                            <h3>Reportes, Gráficos y Paneles Personalizables</h3>
                        </div>
                        <div className="feature">
                            <h3>Multimoneda</h3>
                        </div>
                        <div className="feature">
                            <h3>Clasificación Avanzada de Categorias y Presupuestos</h3>
                        </div> 
                        <div className="feature">
                            <h3>Almacenamiento de Recibos y Plantillas para Transacciones</h3>
                        </div> 
                    </Col>
                    <Col lg={6} className="screens px-4">
                        <div className="screen">
                        <img src={screenshot1} alt="Captura de pantalla de la aplicación" className="img-fluid mb-4" />
                        </div>
                        <div className="screen">
                        <img src={screenshot2} alt="Captura de pantalla de la aplicación" className="img-fluid mb-4" />
                        </div>
                    </Col>
                </Row>
            </Container>

            <footer className="footer">
                <div className="footer-content">
                    <span>DRAGONCASH SAS &copy; 2024. Todos los derechos reservados.</span>
                    <span className="separator">|</span>
                    <a href={process.env.REACT_APP_DOCUMENT_URL} target="_blank" rel="noopener noreferrer">
                        Política de Términos, Datos Personales y Condiciones de Uso
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;