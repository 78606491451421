import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import * as s from '../../utilities/services/user-tool-service';

const PayUResponseComponent = ({ user, info, dispatch }) => {
    const [paymentStatus, setPaymentStatus] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const processPayUResponse = async () => {
            // Obtener los parámetros de la URL
            const params = new URLSearchParams(location.search);
            const referenceCode = params.get('referenceCode');
            const transactionState = params.get('transactionState');
            const transactionId = params.get('transactionId');

            
            console.log(params)
            
            try {

                
                // Aquí deberías hacer una llamada a tu backend para verificar el pago
                // y obtener más detalles si es necesario
                // const response = await fetch('/api/verify-payu-payment', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         referenceCode,
                //         transactionState,
                //         transactionId,
                //         userId: user.user_id
                //     }),
                // });

                // const paymentDetails = await response.json();

                // if (paymentDetails.status === 'approved') {
                //     setPaymentStatus('Success');
                //     // Aquí puedes actualizar el estado de la aplicación si es necesario
                //     // Por ejemplo, actualizar la información del usuario
                //     if (dispatch) {
                //         dispatch({ type: 'UPDATE_USER_PAYMENT_STATUS', payload: true });
                //     }
                // } else if (paymentDetails.status === 'pending') {
                //     setPaymentStatus('Pending');
                // } else {
                //     setPaymentStatus('Error');
                // }
            } catch (error) {
                console.error('Error al procesar la respuesta de PayU:', error);
                setPaymentStatus('Error');
            }
        };

        processPayUResponse();
    }, [location, user.user_id, dispatch]);

    return (
        <div>
            <div className="col-12 text-center">
                <h2 className="payment-title">Resultado del Pago</h2>
                <hr className="divider"/>
            </div>
            
            {paymentStatus === 'Success' && (
                <div className="col-12 text-center">
                    <div className="alert alert-success">
                        ¡Pago exitoso! Gracias por tu compra.
                    </div>
                    <button 
                        className="btn btn-primary"
                        onClick={() => navigate('/dashboard')}
                    >
                        Ir al Dashboard
                    </button>
                </div>
            )}

            {paymentStatus === 'Pending' && (
                <div className="col-12 text-center">
                    <div className="alert alert-warning">
                        Tu pago está pendiente. Por favor, revisa tu correo electrónico para más instrucciones.
                    </div>
                </div>
            )}

            {paymentStatus === 'Error' && (
                <div className="col-12 text-center">
                    <div className="alert alert-danger">
                        Hubo un problema con tu pago. Por favor, intenta nuevamente o contacta a soporte.
                    </div>
                    <button 
                        className="btn btn-secondary"
                        onClick={() => navigate('/payment')}
                    >
                        Volver a Intentar
                    </button>
                </div>
            )}

            {paymentStatus === null && (
                <div className="col-12 text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Procesando...</span>
                    </div>
                    <p>Verificando el estado de tu pago...</p>
                </div>
            )}
        </div>
    );
};

export default PayUResponseComponent;