const axiosBearerConfig = (params) => {
   
    let config = {
        headers: {
            'Accept': 'application/json',               
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + params.user.access_token  
        }
    };

    return config

}

const serverDomain = () => {
    return process.env.REACT_APP_SERVER_URL 
}

const querystring = (parameters) => {
 
    let querystring = [];
    
    if(!parameters)
        return ''

    if(parameters.text)
        querystring.push(`t=${parameters.text}`)

    if(parameters.offset)
        querystring.push(`o=${parameters.offset}`)

    if(parameters.viewId)
        querystring.push(`lv=${parameters.viewId}`)

    if(parameters.object)
        querystring.push(`obj=${parameters.object}`)
        
    if(querystring.length === 0)
        return ''
    
    return '?' + querystring.join('&')
}
  
export { axiosBearerConfig, serverDomain, querystring }