import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'


const updateStoredUser = (newUserData) => {
    console.log(newUserData)
    const currentCookie = Cookies.get('ut')
    console.log(currentCookie)
    if (currentCookie) {

        const currentUser = JSON.parse(currentCookie)
        const updatedUser = { ...currentUser, ...newUserData }

        const currentOptions = Cookies.get('ut', { withAttributes: true })
        const { expires } = currentOptions

        // Mantener la fecha de expiración original
        Cookies.set('ut', JSON.stringify(updatedUser), { expires: new Date(expires) })
    }
}

const userProfileSlice = createSlice({
    name: 'user-profile',
    initialState: { loading: false,  item: undefined, error: undefined },
    reducers: {
       
      get(state, action) {
        state.loading = true
      },

      getResponse(state, action) {

        state.loading = false
        state.item = action.payload 
      },

      update(state, action) {
        state.loading = true
      },

      updateResponse(state, action) {

        state.loading = false
        state.item = action.payload
        
        // from here you should update the user
        updateStoredUser({
            currency_id: action.payload.currency_id,
            currency_code: action.payload.currency_code,
            is_multicurrency: action.payload.is_multicurrency,})
      },
 
      error(state, action) {
          state.loading = false
          state.error = action.payload
  
          console.log(action.payload)
      }
    }
})

export const { 
    get, getResponse,
    update, updateResponse,
    error
} = userProfileSlice.actions;

export default userProfileSlice.reducer