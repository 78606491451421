import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
 
import * as s from '../../utilities/services/user-automated-email-service'
import * as tc from '../../common/configurations/text-constants'

const UserAutomatedVerification = ({ closeModal, user }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id } = useParams()

    const [time, setTime] = useState(60)

    const [code, setCode] = useState('')
    const [error, setError] = useState(true)

    const onChangeCode = (e) => {
        setCode(e.target.value)
        setError(false)
    }

    const verifyHandler = async () => {
        const response = await s.verifyCode({ payload: { id, code }, user }, dispatch)
        if (response.success) {
            navigate(0)
        } else {
            setError(true)
        }
    }

    useEffect(() => {
         
        const intervalId = setInterval(() => {
            setTime((currentTime) => {
                if (currentTime <= 0) {
                    clearInterval(intervalId) // Clear interval if time is up
                    closeModal()
                    return 0
                }
 
                return currentTime - 1 // Decrement the time
            })
        }, 1000) // Interval set for 1 second

        return () => clearInterval(intervalId)
    }, [])

    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Verificación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p className="m-0 mb-2 text-secondary">
                        Por favor, introduce el código de verificación que hemos enviado a tu
                        dirección de correo electrónico para continuar
                    </p>
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                                Codigo
                            </label>
                            <input
                                type="text"
                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                placeholder="Entra el Código"
                                value={code}
                                required
                                onChange={onChangeCode}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3 text-center">
                            <h1>{time}</h1>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={closeModal}>
                    {tc.BUTTON_CANCEL_LABEL}
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    variant="primary"
                    onClick={verifyHandler}
                >
                    Verificar
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserAutomatedVerification
