import React, { Fragment, useState } from "react";
import { NavDropdown } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'; 

import * as tc from '../../../common/configurations/text-constants'
import * as uservice from '../../../utilities/services/user-service'
 
import MaterialIcon from "../../../common/icons/material-icon";

import './authenticated-container.css'
   
const AuthenticatedContainerCompactSideHeader = ({user, configs, select}) => {
    
    return (
        <div className="card mb-1">
            <div className="card-body d-flex flex-row justify-content-center py-2">
                {
                    configs.map((c, index) => {
                        return (
                            <div className="list-group-item-action text-center" onClick={select} key={index}>
                                <Link className="list-group-item" to={c.object.basePath}>
                                    <MaterialIcon obj={c.object.name} tooltip={c.object.pluralLabel}/>
                                </Link>
                            </div>
                            
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AuthenticatedContainerCompactSideHeader