import React from "react"

import * as tc from '../../configurations/text-constants'

const EmptyList = ({configs}) => {

    return (
        <table className="table table-bordered"> 
            <tbody> 
                <tr className="row">
                    <td className="text-center" colSpan={configs.list.items.length}>
                        {tc.LIST_EMPTY_LABEL}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default EmptyList