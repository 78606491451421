import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from 'react-redux'
 
import { Modal } from "react-bootstrap";
import UserContext from '../../user/user-context';
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"

const ScheduleModal = ({templateid, service, configs}) => {
    
    const FRECUENCY_NONE = '--'
    const FRECUENCY_DAILY = 'd'
    const FRECUENCY_MONTHLY = 'm'
    const FRECUENCY_WEEKLY = 's'
    const FRECUENCY_DAILY_TEXT = tc.FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_DAILY
    const FRECUENCY_MONTHLY_TEXT= tc.FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_MONTHLY
    const FRECUENCY_WEEKLY_TEXT = tc.FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY_WEEKLY
 
    const user = useContext(UserContext)
 
    const [showModal, setShowModal] = useState(false) 
    const [schedule, setSchedule] = useState({})
    const [isInvalid, setIsInvalid] = useState(false)

    const dispatch = useDispatch()
   
    let caction = configs.c_actions.find(a => a.label == tc.BUTTON_SCHEDULE_LABEL)
    if(caction)
        caction.handler = () => setShowModal(true)
     
    const changeHandler = (property, value) => {   
        schedule[property] = value
        setSchedule({...schedule})

        if(property === 'frecuency') {
            setIsInvalid(false)
            setSchedule({...schedule, value: ""})
        }
        else if(property === 'value') {
            setIsInvalid(false)
        }
        
    }

    const saveHandler = async () => {

        if((schedule.frecuency === FRECUENCY_MONTHLY || schedule.frecuency === FRECUENCY_WEEKLY)
         && !schedule.value) {
            setIsInvalid(true)
            return
        }
            
        if(isInvalid.length > 0)
            return

        if(schedule.frecuency === FRECUENCY_NONE) {
            if(!schedule.id) // there is not one
                return
 
            await service.remove({payload: {id: schedule.id}, user: user }, dispatch)
        }   
        else {
            const response = await service.add({payload: {...schedule, template_id: templateid}, user: user }, dispatch)
            setSchedule(response.data)
        } 

        setShowModal(false)
    }

    const deleteHandler = async () => {

        service.remove({payload: {id: schedule.id}, user: user }, dispatch)

        setSchedule({})
        setShowModal(false)
    }

    const getWeeklyValues = () => {
 
        const values = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
        return values.map((v, i) => {
            return <option key={i} value={i}>{v}</option>
        })
    }

    const getMonthlyValues = () => {
        const values = Array.from({length: 31}, (_, i) => i + 1)
        return values.map((v, i) => {
            return <option key={i}>{v}</option>
        })
    }

    useEffect(()=>{
        const load = async () => {
            const response = await service.get({payload: {id: templateid}, user }, dispatch)

            if(!response.success) {
                setSchedule({frecuency: FRECUENCY_NONE})
                return
            }

            setSchedule({...response.data})
        }
        
        load()
    }, [])
  
    return (
        <Modal show={showModal} onHide={()=> setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{tc.FUNCTION_SCHEDULE_TEMPLATE_HEADER}</Modal.Title>
            </Modal.Header>        
            <Modal.Body> 
            <form className="needsValidation" noValidate>
                <div className="row">
                <div className="mb-3 col-6" >
                    <label className="fw-light form-label">{tc.FUNCTION_SCHEDULE_TEMPLATE_FRECUENCY}</label>
                    <select className="form-control" onChange={(e)=> changeHandler('frecuency', e.target.value)} value={schedule.frecuency}>
                        <option value={FRECUENCY_NONE}>{tc.FUNCTION_SCHEDULE_TEMPLATE_NO_OPTION}</option>
                        <option value={FRECUENCY_DAILY}>{FRECUENCY_DAILY_TEXT}</option>
                        <option value={FRECUENCY_WEEKLY}>{FRECUENCY_WEEKLY_TEXT}</option>
                        <option value={FRECUENCY_MONTHLY}>{FRECUENCY_MONTHLY_TEXT}</option>
                    </select> 
                </div>
                <div className="mb-3 col-6" >
                    <label className="fw-light form-label">{tc.FUNCTION_SCHEDULE_TEMPLATE_VALUE}</label>
                    {
                        schedule.frecuency === FRECUENCY_NONE && 
                        <select className="form-control select" disabled>
                            <option></option>
                        </select> 
                    }
                    {
                        schedule.frecuency === FRECUENCY_DAILY && 
                        <select className="form-control select" disabled>
                            <option></option>
                        </select> 
                    }
                    {
                        schedule.frecuency === FRECUENCY_WEEKLY && 
                        <select className={`form-control select ${isInvalid?"is-invalid": ""}`}  onChange={(e)=> changeHandler('value', e.target.value)} value={schedule.value}>
                            <option>{tc.FUNCTION_SCHEDULE_TEMPLATE_NO_OPTION}</option>
                            {getWeeklyValues()}
                        </select> 
                    }
                    {
                        /**is-invalid */
                        schedule.frecuency === FRECUENCY_MONTHLY && 
                        <select className={`form-control select ${isInvalid?"is-invalid": ""}`} onChange={(e)=> changeHandler('value', e.target.value)} value={schedule.value}>
                            <option>{tc.FUNCTION_SCHEDULE_TEMPLATE_NO_OPTION}</option>
                            {getMonthlyValues()}
                        </select> 
                    }
                </div>
                </div>
            </form>     
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-secondary btn-sm" onClick={()=> setShowModal(false)}>{tc.BUTTON_CANCEL_LABEL}</button>
                <button type="button" className="btn btn-primary btn-sm" variant="primary" onClick={saveHandler} >{tc.BUTTON_SAVE_LABEL}</button>
                {
                    schedule && schedule.id && 
                    <button type="button" className="btn btn-danger btn-sm" variant="primary" onClick={deleteHandler} >{tc.BUTTON_REMOVE_LABEL}</button>
                }

            </Modal.Footer>
        </Modal>
    )
}
export default ScheduleModal