import React, { useEffect, useState } from "react";
import ChartShowPage from "../../chart/chart-show-page";
import ReportShowPage from "../../report/report-show-page";
import { Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux'

import * as c from "../../../common/configurations/constants";
import * as tc from "../../../common/configurations/text-constants";


import { refreshSections } from '../../../utilities/redux/actions/dashboard-slice'
import Selector from "../../../common/selectors/selector";

const DashboardSectionItemSelector = ({add, user, readonly}) => {
         
    const TYPE_COMPONENT_REPORT = tc.OBJECT_REPORT_LABEL
    const TYPE_COMPONENT_REPORT_ID = '1'
    const TYPE_COMPONENT_CHART = tc.OBJECT_CHART_LABEL
    const TYPE_COMPONENT_CHART_ID = '2'
  
    const [showModal, setShowModal] = useState()
    const [type, setType] = useState(TYPE_COMPONENT_REPORT_ID)
    const [component, setComponent] = useState()
    
    const typeChange = (e) => {
        setType(e.target.value)
    }

    const componentChange = (e) => {
        setComponent(e)
    }

    const setHandler = ()=> { 
        add(component)
        setShowModal(false)
    }
 
    return (
        <div className="card-body" style={{padding: '0px' }}>
            <div  style={{ padding: '5px', border: '1px dashed #ccc', borderRadius: '5px'}}>
                {
                    !readonly &&
                    <div className="text-center">
                        <i className="bi bi-plus " style={{fontSize: '2.5rem', color: "gray"}} onClick={()=> setShowModal(true)}></i>
                    </div>
                }
            </div>

            <Modal show={showModal} onHide={()=> setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{tc.DASHBOARD_SECTION_COMPONENT_TITLE}</Modal.Title>
                    </Modal.Header>        
                    <Modal.Body>
                        <form className="needsValidation" noValidate>
                            <div className="mb-3">
                                <label className="fw-light form-label">{tc.DASHBOARD_SECTION_COMPONENT_TYPE}</label>
                                <div>
                                    <select className="form-select" onChange={typeChange} value={type}>
                                        <option value={TYPE_COMPONENT_REPORT_ID}>{TYPE_COMPONENT_REPORT}</option>
                                        <option value={TYPE_COMPONENT_CHART_ID}>{TYPE_COMPONENT_CHART}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                
                                <label className="fw-light form-label">{tc.DASHBOARD_SECTION_COMPONENT_COMPONENT}</label>
                                {
                                    type === TYPE_COMPONENT_REPORT_ID &&
                                    <div>
                                        <Selector user={user} vConfig={{context: c.SELECTOR_CONTEXT_REPORT}} value={{}} onChange={(v) => componentChange(v)}/>
                                    </div>
                                }
                                {
                                    type === TYPE_COMPONENT_CHART_ID &&
                                    <div>
                                        <Selector user={user} vConfig={{context: c.SELECTOR_CONTEXT_CHART}} value={{}} onChange={(v) => componentChange(v)}/>
                                    </div>
                                }
                                
                            </div>
                        </form>         
                    </Modal.Body>
                
                    <Modal.Footer>
                        <button className="btn btn-secondary btn-sm" onClick={()=> setShowModal(false)}>{tc.BUTTON_CANCEL_LABEL}</button>
                        <button type="button" className="btn btn-primary btn-sm" variant="primary" onClick={setHandler}>{tc.BUTTON_SAVE_LABEL}</button>
                    </Modal.Footer>
            </Modal>

        </div>
        
    )
}

export default DashboardSectionItemSelector