import React, { useState, useEffect } from "react";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RelatedList from "../../../related-lists";

import * as tc from '../../../configurations/text-constants'

const DetailPageRelatedList = ({user, element, configs}) => {
 
    const [activeTab, setActiveTab] = useState(configs.relatedlist[0].name);

    useEffect(()=> {
        setActiveTab(configs.relatedlist[0].name)
    }, [element])

    return (
        <Tabs activeKey={activeTab} className="mb-3"  onSelect={t => setActiveTab(t)} > 
            {
                configs.relatedlist.map(rl => {
                    return (
                        <Tab key={rl.name} eventKey={rl.name} title={rl.label}>
                            <div className="card">
                                <div className="card-body">
                                    <RelatedList user={user} target={element} parameters={rl.parameters} name={rl.name}/>
                                </div>
                            </div>
                        </Tab>
                    )
                })
            } 
        </Tabs> 
    )

    
}

export default DetailPageRelatedList