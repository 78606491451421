import React, { useEffect, useState } from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Link } from 'react-router-dom'
import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container'

import * as uservice from '../../utilities/services/user-service'
import * as uactions from '../../utilities/redux/actions/user-slice'

import validator from '../user-validator'

import './login-page.css'

const LoginForm = () => {
    const dispatch = useDispatch()

    const error = useSelector(
        (state) => state.user.error,
    )

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validations, setValidations] =
        useState({})
    const [rememberMe, setRememberMe] =
        useState(false)

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        // validation
        if (
            !validator.validation({
                type: validator.LOGIN,
                parameters: { email, password },
                setInputs: setValidations,
            })
        )
            return

        const response = await uservice.login(
            {
                username: email,
                password: password,
                rememberMe: rememberMe,
            },
            dispatch,
        )
 
        if (response.success) navigate(`/activation`)
    }

    const updateHandler = async (e, set) => {
        // clean error
        if (error) dispatch(uactions.error())

        set(e.target.value)
    }

    useEffect(()=> {
        dispatch(uactions.error())
    }, [])

    return (
        <AnonymousFormContainer>
            
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5">
                    <h2 className="fs-5 fw-normalmb-4">
                        Inicio de Sesión
                    </h2>

                    <div className="text-center mb-3"></div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label
                                        htmlFor="email"
                                        className="form-label"
                                    >
                                        Correo
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${validations.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        id="email"
                                        placeholder="name@example.com"
                                        value={
                                            email
                                        }
                                        required
                                        onChange={(
                                            e,
                                        ) =>
                                            updateHandler(
                                                e,
                                                setEmail,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label
                                        htmlFor="password"
                                        className="form-label"
                                    >
                                        Contraseña
                                    </label>
                                    <input
                                        type="password"
                                        className={`form-control ${validations.password ? 'is-invalid' : ''}`}
                                        name="password"
                                        id="password"
                                        placeholder="Contraseña"
                                        value={
                                            password
                                        }
                                        required
                                        onChange={(
                                            e,
                                        ) =>
                                            updateHandler(
                                                e,
                                                setPassword,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex gap-2 justify-content-between">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                                rememberMe
                                            }
                                            name="rememberMe"
                                            id="rememberMe"
                                            onChange={() =>
                                                setRememberMe(
                                                    !rememberMe,
                                                )
                                            }
                                        />
                                        <label
                                            className="form-check-label text-secondary"
                                            htmlFor="rememberMe"
                                        >
                                            Mantenerme
                                            conectado
                                        </label>
                                    </div>
                                    <Link
                                        to={
                                            '/password/request'
                                        }
                                        className="text-decoration-none"
                                    >
                                        ¿Olvidaste
                                        tu
                                        contraseña?
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-grid my-3">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={
                                            handleSubmit
                                        }
                                    >
                                        Iniciar
                                        Sesión
                                    </button>
                                </div>
                            </div>
                            <div className="col-12">
                                <p className="m-0 text-secondary text-center">
                                    ¿No
                                    tienes
                                    una
                                    cuenta?{' '}
                                    <Link
                                        to={
                                            '/signup'
                                        }
                                    >
                                        Regístrate
                                    </Link>
                                </p>
                            </div>
                        </div>
                       
                    </form>
                
                    {error && (
                        <p className="m-0 text-danger">
                            {error}
                        </p>
                    )}
                    
                </div>
            </div>
        </AnonymousFormContainer>
    )
    
}

export default LoginForm
