import React, { Fragment, useState } from "react";
import { NavDropdown } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'; 

import * as tc from '../../../common/configurations/text-constants'
import * as uservice from '../../../utilities/services/user-service'
 
import MaterialIcon from "../../../common/icons/material-icon";

import './authenticated-container.css'
   
const AuthenticatedContainerSideHeader = ({user, configs}) => {
    
    return (
        <div className="custom-side-header side-header">
            {
                configs.map((c, index) => {
                        
                    return (
                        <Link className="list-group-item list-group-item-action custom-list-group-item-action" to={c.object.basePath} key={index}>
                            <MaterialIcon obj={c.object.name} tooltip={c.object.pluralLabel}/>
                        </Link>
                    )
                })
            }
        </div>
        
    )
}

export default AuthenticatedContainerSideHeader