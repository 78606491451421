import { Route } from "react-router-dom";
  
import LandingPage from '../../user/landing-page'
import LoginPage from '../../user/login-page/login-page'
import SignupFormPage from '../../user/signup-page'
import SignupSuccessPage from '../../user/signup-page/success-signup-page'

import VerificationPage from '../../user/verification-page' 
import PasswordPage from '../../user/password-page/update-password-page' 
import RequestPasswordPage from '../../user/password-page/request-password-page'

const routes =  
    <Route path="/">
      
        <Route index element={<LandingPage />} />

        <Route path="landing" element={<LandingPage />} />
        
        <Route path="login" element={<LoginPage />} />
        <Route path="activation" element={<VerificationPage />} />

        <Route path="signup" element={<SignupFormPage />}/>
        <Route path="signup-scc" element={<SignupSuccessPage />}/>
       

        <Route path="password/update/:token" element={<PasswordPage />} />
        <Route path="password/request" element={<RequestPasswordPage />} />

        <Route element={<LoginPage />} />
  
    </Route> 

export default routes
