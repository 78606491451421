import React, { Fragment, useState } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import AutoHeightContainer from "../../../auto-height-container/auto-height-container";
import * as tc from "../../../configurations/text-constants";

import '../page.css'


const SetupPageBody = ({body, configs, element}) => {
    
    const [elementCopy, setElementCopy] = useState({...element})

    const navigate = useNavigate()

    const cancelHandler = (e) => {
        navigate(`/${configs.object.basePath}/${element.id}/view`)
    } 

    const saveHandler = async (e) => {
        // save setup changes and go to the list
        configs.actions.setup.handler({element: elementCopy})
    }

    return ( 
        <Fragment>
            <div className="card page-edit-container"> 
                <div className="card-body">
                    <AutoHeightContainer bottomMargin={80}>
                        {body} 
                    </AutoHeightContainer>
                </div>
                <div className="card-footer text-center page-action-bottoms"> 
                    <button type="button" className="btn btn-secondary btn-sm me-2" onClick={cancelHandler}>
                        { tc.BUTTON_CANCEL_LABEL }
                    </button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={saveHandler}>
                        { tc.BUTTON_SAVE_LABEL }
                    </button> 
                </div>
            </div>
           
        </Fragment>
           
         
    )
}

export default SetupPageBody