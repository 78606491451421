import React from "react";

import SimpleHeader from "../../../layouts/headers/simple/simple-header";

const NewChildPageHeader = ({configs}) => {
    return (
        <SimpleHeader configs={configs} text={`New ${configs.object.label}`} >
        
        </SimpleHeader>
    )
}

export default NewChildPageHeader