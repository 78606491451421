import React from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';

import ListHeader from "../../headers/list";
import * as tc from "../../../configurations/text-constants";

const SimplePageHeader = ({configs}) => {

    const navigate = useNavigate()

    const newHandler = () => {
        navigate(`/${configs.object.basePath}/new`)
    }

    return (
        <ListHeader configs={configs} text={configs.object.pluralLabel} >
            <div className="hstack">
                {
                    configs.actions.add.enable &&
                    <button type="button" className="btn btn-primary btn-sm" onClick={newHandler}>{ tc.BUTTON_ADD_LABEL}</button>
                }
            </div>
        </ListHeader>
        
    )
}

export default SimplePageHeader