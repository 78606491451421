import React from "react"

import * as tc from '../../configurations/text-constants'

const LoadingComponent = ({configs, isText}) => {

    return (
        <div className="row d-flex justify-content-center align-items-center" style={{paddingTop:'100px', height: '100%'}}>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="spinner-border" role="status"></div>
                <strong>Cargando...</strong>
            </div>
        </div>
    )
}

export default LoadingComponent