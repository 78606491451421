import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"

import expenseAccountConfig from "../../accounts/account-expense/configs"
import bankConfig from "../../expense-supporters/payment-method/payment-method-bank/configs" 
import cardConfig from "../../expense-supporters/payment-method/payment-method-card/configs" 
import cashConfig from "../../expense-supporters/payment-method/payment-method-cash/configs" 

import vendorConfig from '../../third-parties/vendor/configs'
import automatedEmailConfig from '../../automations/transaction-automated-email/configs'

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        addTemplate: {
            enable: true,
            handler: function() { console.log('template')}
        },
        convertToRealAccount: {
            enable: true,
            label: tc.BUTTON_REAL_ACCOUNT_PAYABLE_LABEL,
            handler: function() { console.log('new real account')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        name: 'date',
                        type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                        label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                        isRequired: true
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'currency_id',
                        label: tc.OBJECT_FIELD_PROFILE_CURRENCY,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_USER_CURRENCY,
                        isSelectorLinked: false,
                        isMultiCurrency: true,
                        isRequired: false
                    },
                ]
            }, 
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                        isCreatable: false,
                        isMultiCurrency: true,
                        isEditable: false
                    },
                    {
                        ...elementTemplate,
                        name: 'rate',
                        type: c.OBJECT_FIELD_DATA_TYPE_NUMBER,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_RATE,
                        isCreatable: false,
                        isMultiCurrency: true,
                        isEditable: false
                    },
                ]
            },
            {
                type: 'separator_space'
            }, 
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'expense_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_EXPENSE_LEAF_ACCOUNT,
                        isSelectorLinked: true, //expenseAccountConfig.object.basePath,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'payment_method_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_PAYMENT_METHOD,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_PAYMENT_METHOD,
                        isSelectorLinked: true,
                        isRequired: true
                    },
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'vendor_id',
                        label: tc.OBJECT_VENDOR_LABEL,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_VENDOR,
                        isSelectorLinked: true, //vendorConfig.object.basePath,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        name: 'tags',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA,
                        label: tc.OBJECT_FIELD_TRANSACTION_TAGS,
                        isRequired: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'mdi mdi-cart-outline transaction',
        color: '#C62828'
    },

    object: {
        name: 'transaction_expense',
        label: tc.OBJECT_TRANSACTION_EXPENSE_NAME_LABEL,
        pluralLabel: tc.OBJECT_TRANSACTION_EXPENSE_PLURAL_LABEL,
        basePath: 'expenses',
        sliceName: 'expense',
        menuLabel: tc.NAV_MENU_TRANSACTION_EXPENSE_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                compactViewHeader: true,
                isLinked: true,
            }, 
            {
                name: 'date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                compactViewLeftDetail: true,
                isLinked: false
            },
            {
                name: 'amount',
                type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                compactViewRightDetail: true,
                isLinked: false
            },
            {
                name: 'expense',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE,
                isLinked: false
            },
            {
                name: 'payment_method',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_PAYMENT_METHOD,
                isLinked: false
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    },

    relatedlist: [
        {
            label: tc.OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_NAME,
            name: 'transaction-receipt',
            parameters: {
                type: "expense"
            }
        }
    ] 
}
 
export default configs