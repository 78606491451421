import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container'

import * as uservice from '../../utilities/services/user-service'
import * as uactions from '../../utilities/redux/actions/user-slice';
import validator from '../user-validator'
 
const UpdatePasswordPage = () => {
 
    const { token } = useParams()
    const error = useSelector((state) => state.user.error)

    const [validations, setValidations] = useState({});
    const [internalError, setInternalError] = useState('')
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
 
    const dispatch = useDispatch()
    
    const navigate = useNavigate()

    const handleSubmit = async e => { 

        try {

            // validation
            if(!validator.validation({type: validator.PASSWORD_CHANGE, parameters: {password, repassword}, setInputs: setValidations}))
                return

            const response = await uservice.changePassword({ token, password: password }, dispatch)
            if(response.success) {
                // go to login page
                navigate(`/login`)
            }
        }
        catch(e) {
            // if you get some errors
        }

    }

    const updateHandler = async (e, set) => {

        // clean error
        if(error)
            dispatch(uactions.error());

        set(e.target.value)
    }

    const cancelHandler = async () => {
        // go to login page
        navigate(`/login`)
    }

    useEffect(() => {

        if(!token)
            navigate(`/login`)
    })

    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5"> 
                
                    <h2 className="fs-5 fw-normalmb-4">Cambio de Contraseña</h2>

                    <div className="text-center mb-3">
                    
                    </div>
                    <form action="#!">
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Contraseña</label>
                                    <input type="password" className={`form-control ${validations.password? "is-invalid": ""}`} name="password" id="password" placeholder="Contraseña" value={password} required onChange={(e)=>updateHandler(e, setPassword)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Confirmar Contraseña</label>
                                    <input type="password" className={`form-control ${validations.repassword? "is-invalid": ""}`} name="password" id="password" placeholder="Confirmar Contraseña" value={repassword} required onChange={(e)=>updateHandler(e, setRepassword)}/>
                                </div>
                            </div>
                             
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-secondary " type="button" onClick={cancelHandler}>Cancelar</button>
                                    </div>
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-primary " type="button" onClick={handleSubmit}>Cambiar</button>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </form>
                    {
                        error &&
                        <p className="m-0 text-danger">{error}</p>
                    }
                    {
                        internalError &&
                        <p className="m-0 text-danger">{internalError}</p>
                    } 
                </div>
            </div>
        </AnonymousFormContainer>
    )
}

export default UpdatePasswordPage