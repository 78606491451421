// system imports
import React, { useState, useEffect } from 'react';

import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';

// custom imports
import {store} from './utilities/redux/store.js';
import Container from './user/containers'; 

// css imports
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'react-toastify/dist/ReactToastify.css';

import './App.css'
 
const useWindowSize = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return isMobile;
  };

function App() {
     
    const isMobile = useWindowSize();

    useEffect(() => {
        // setAppClass(isMobile ? 'application-mobile' : 'application-desktop') 
        document.body.classList.add(isMobile ? 'application-mobile' : 'application-desktop');
      }, [isMobile]);

    return (
        <div className={`app `}>
            <Provider store={store}>
                <BrowserRouter> 
                    <Container/>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;
