import React, { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import InputField from "../../../layouts/fields/input-field";
import FloatMessage from "../../../float-messages"; 
import { getField, getFieldLayout, validateInputs } from "../../../layouts/fields/utility-field";
import AutoHeightContainer from "../../../auto-height-container/auto-height-container";

const NewChildPageBody = ({user, configs, message, parent}) => {
  
    const [elementCopy, setElementCopy] = useState({})
    const [invalidFields, setInvalidFields] = useState([])
    const [saveApplied, setSaveApplied] = useState(false)
 
    const navigate = useNavigate()
 
    const changeHandler = (value, configElement) => { 
 
        elementCopy[configElement.name] = value
        setElementCopy({...elementCopy}) 
    }

    const cancelHandler = (e) => {
        navigate(`/${configs.object.basePath}`)
    }

    const saveHandler = (e) => {

        setSaveApplied(true)

        const noValidInputs = validateInputs(elementCopy, configs)
 
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }

        // save changes and go to the list
        configs.actions.add.handler({element: {...elementCopy, parent_id: parent.id}})
    }
 
    const getColumn = (e, ncols, index) => {
        return getField({ isValid: !saveApplied || invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: {...e, isVisible: e.isVisible && e.isCreatable}, ncolumns: ncols, index, changeHandler, Field: InputField, action:'create'})
    }
 
    return (
        <div className="card "> 

                <div className="card-body">
                    <AutoHeightContainer bottomMargin={80}>
                        <form className="needsValidation" noValidate>
                            <FloatMessage message={message}/>

                            { getFieldLayout(configs, getColumn) }
                        </form>
                    </AutoHeightContainer>
                </div>

                <div className="card-footer text-center page-action-bottoms"> 
                    <button type="button" className="btn btn-secondary btn-sm me-2" onClick={cancelHandler}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={saveHandler}>
                        Save
                    </button> 
                </div>
            </div>
        
    )
}

export default NewChildPageBody