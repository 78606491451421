import React from "react";

import SimpleHeader from "../../headers/simple/simple-header";

const NewPageHeader = ({configs}) => {
    return (
        <SimpleHeader configs={configs} text={`Nuevo ${configs.object.label}`} >
        
        </SimpleHeader>
        
    )
}

export default NewPageHeader