import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Dropdown } from "react-bootstrap";
 
import { getField, getFieldLayout, validateInputs } from "../../common/layouts/fields/utility-field";
import InputField from "../../common/layouts/fields/input-field";

import * as s from '../../utilities/services/transaction-income-service'
import OutputField from "../../common/layouts/fields/output-field";
import configTemplates from '../../common/configurations'

import * as tc from "../../common/configurations/text-constants";
import * as c from "../../common/configurations/constants"
import * as cc from "../../common/configurations/color-constants"
 
const elementTemplate = configTemplates.getObjectElement()

const configContainer = {

    expense: {
 
        layout: {
            sections: [
                {
                    type: 'data',
                    ncols: 2,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'name',
                            type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                            label: tc.OBJECT_FIELD_GENERIC_NAME,
                            isRequired: true
                        }, 
                        {
                            ...elementTemplate,
                            isVisible: false
                        }
                    ]
                },
                {
                    type: 'data',
                    ncols: 2,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'account_id',
                            label: tc.OBJECT_FIELD_TRANSACTION_EXPENSE_EXPENSE,
                            type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                            context: c.SELECTOR_CONTEXT_EXPENSE_LEAF_ACCOUNT,
                            isRequired: true,
                            isEditable: false
                        },
                        {
                            ...elementTemplate,
                            name: 'amount',
                            type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                            label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                            isRequired: true
                        },
                    ]
                } 
            ],
            boostrapIcon: 'bi-bag-dash',
            color: '#C62828'
        },
    
        object: {
            name: 'transaction_expense',
            label: tc.OBJECT_TRANSACTION_EXPENSE_NAME_LABEL,
            pluralLabel: tc.OBJECT_TRANSACTION_EXPENSE_PLURAL_LABEL,
            basePath: 'expenses',
            sliceName: 'expense',
            menuLabel: tc.NAV_MENU_TRANSACTION_EXPENSE_LABEL
        },
    },

    asset: {
 
        layout: {
     
            sections: [
                {
                    type: 'data',
                    ncols: 2,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'name',
                            type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                            label: tc.OBJECT_FIELD_GENERIC_NAME,
                            isRequired: true
                        }, 
                        {
                            ...elementTemplate,
                            isVisible: false
                        }
                    ]
                },
                
                {
                    type: 'data',
                    ncols: 2,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'account_id',
                            label: tc.OBJECT_FIELD_TRANSACTION_ASSET_ACTIVE,
                            type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                            context: c.SELECTOR_CONTEXT_ACTIVE_LEAF_ACCOUNT,
                            isRequired: true,
                            isEditable: false
                            

                        },
                        {
                            ...elementTemplate,
                            name: 'amount',
                            type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                            label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                            isRequired: true
                        },
                    ]
                }
            ],
    
            boostrapIcon: 'bi-bag-plus-fill',
            color: cc.ASSET_TRANSACTION
        },
    
        object: {
            name: 'transaction_asset',
            label: tc.OBJECT_TRANSACTION_ASSET_NAME_LABEL,
            pluralLabel: tc.OBJECT_TRANSACTION_ASSET_PLURAL_LABEL,
            basePath: 'assets',
            sliceName: 'asset',
            menuLabel: tc.NAV_MENU_TRANSACTION_ASSET_LABEL
        },
    },

    payment: {
 
        layout: {
     
            sections: [
                {
                    type: 'data',
                    ncols: 2,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'name',
                            type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                            label: tc.OBJECT_FIELD_GENERIC_NAME,
                            isRequired: true
                        }, 
                        {
                            ...elementTemplate,
                            isVisible: false
                        }
                    ]
                },
                {
                    type: 'data',
                    ncols: 2,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'account_id',
                            label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_PASSIVE,
                            type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                            context: c.SELECTOR_CONTEXT_PASSIVE_LEAF_ACCOUNT,
                            isRequired: true,
                            isEditable: false
                        },
                        {
                            ...elementTemplate,
                            name: 'amount',
                            type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                            label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                            isRequired: true
                        },
                    ]
                },
                {
                    type: 'data',
                    ncols: 3,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'interests',
                            type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                            label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INTERESTS,
                            isRequired: false
                        },
                        {
                            ...elementTemplate,
                            name: 'insurances',
                            type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                            label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_INSURANCES,
                            isRequired: false
                        },
                        {
                            ...elementTemplate,
                            name: 'other_expenses',
                            type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                            label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_OTHER_EXPENSES,
                            isRequired: false
                        }
                    ]
                }
            ],
    
            boostrapIcon: 'bi-bag-check-fill',
            color: cc.LIABILITY_PAYMENT_TRANSACTION
        },
    
        object: {
            name: 'transaction_liability_payment',
            label: tc.OBJECT_TRANSACTION_LIABILITY_PAYMENT_NAME_LABEL,
            pluralLabel: tc.OBJECT_TRANSACTION_LIABILITY_PAYMENT_PLURAL_LABEL,
            basePath: 'liability-payments',
            sliceName: 'liabilityPayment',
            menuLabel: tc.NAV_MENU_TRANSACTION_LIABILITY_PAYMENT_LABEL
        },
     
    }
    
}

const NewComponent = ({item, user, configs, close, oldItem, oldItemIndex}) => {
       
    const [elementCopy, setElementCopy] = useState({...oldItem})   
    
    const [invalidFields, setInvalidFields] = useState([])
    const [saveApplied, setSaveApplied] = useState(false)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const changeHandler = (value, configElement) => { 
 
        elementCopy[configElement.name] = value
        setElementCopy({...elementCopy}) 
    }

    const cancelHandler = (e) => {
        close()
    }

    const saveHandler = (e) => {

        setSaveApplied(true)

        const noValidInputs = validateInputs(elementCopy, configs)
         
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }

        // save changes and go to the list

        // elementCopy is the new deduction that it should be included
        // inside the item, and that it what we need to update
        let deductions = [...item.deductions]
        if(!deductions)
            deductions = []

        // you are making an update
        if(oldItem) {
            deductions[oldItemIndex] = {...elementCopy, obj: configs.object.name}
        }
        else
            deductions.push({...elementCopy, obj: configs.object.name})

        s.update({payload: {...item, deductions: deductions}, user: user}, dispatch)
    }

    useEffect(()=> { 
    }, [])
 
    const getColumn = (e, ncols, index) => {
        return getField({ isValid: !saveApplied || invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: e, ncolumns: ncols, index, changeHandler, Field: InputField, action: (oldItem? 'edit': 'create')})
    }
  
    return (
        <Fragment>
            <div className="card"> 
                <div className="card-header">
                    {configs.object.label}
                </div> 
               <div className="card-body">
                    <form className="needsValidation" noValidate>
                        { getFieldLayout(configs, getColumn) }
                    </form>
                </div>
                <div className="card-footer text-end"> 
                    <button type="button" className="btn btn-secondary btn-sm me-2" onClick={cancelHandler}>
                        { tc.BUTTON_CANCEL_LABEL }
                    </button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={saveHandler}>
                        { tc.BUTTON_SAVE_LABEL }
                    </button> 
                </div>
            </div>
        </Fragment>
    )
}
  
const IncomeDeductionsComponent = ({user}) => {
 
    const item = useSelector(state => state.income.item)
    
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [configs, setConfigs] = useState()
    const [deduction, setDeduction] = useState({})
    const [deductionIndex, setDeductionIndex] = useState()
 
    const navigate = useNavigate()
    const dispatch = useDispatch()
   
    const editHandler = (item, index) => {
 
        setDeduction(item)
        setDeductionIndex(index)

        if(item.obj == configContainer.expense.object.name)
            setConfigs(configContainer.expense)
        else if(item.obj == configContainer.asset.object.name)
            setConfigs(configContainer.asset)
        else if(item.obj == configContainer.payment.object.name)
            setConfigs(configContainer.payment)

        setShowEditModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
        setShowEditModal(false)


        setDeduction({})
        setDeductionIndex()
    }

    const removeHandler = (index) => {
        let deductions = [...item.deductions]
        deductions.splice(index, 1) 

        s.update({payload: {...item, deductions: deductions}, user: user}, dispatch)
    }

    const newExpense = () => {
        setConfigs(configContainer.expense)
        setShowModal(true)
    }

    const newAsset = () => {
        setConfigs(configContainer.asset)
        setShowModal(true)
    }

    const newPayment = () => {
        setConfigs(configContainer.payment)
        setShowModal(true)
    }
   
    // return nothing if there is not item or 
    // the values of amount and the real value value came in to the account 
    // has not different
    if(!item || item.amount <= item.net_income)
        return <div />

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col">
                        <span className="">
                            <b>{`${tc.OBJECT_FIELD_TRANSACTION_INCOME_DEDUCTIONS}`}</b>
                        </span>
                    </div>
                    <div className="col">
                        {
                            Math.round(item.other_deductions) == 0 &&
                            <span className="text-success">
                                <b>Monto Deducido</b>
                            </span>
                        }
                        {
                            Math.round(item.other_deductions) != 0 &&
                            <span className="text-danger">
                                <b>Monto por Deducir: <OutputField value={item.other_deductions} vConfig={{type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY}} user={user}/></b>

                            </span>
                        }
                        {
                            Math.round(item.other_deductions) < 0 &&
                            <span className="text-danger mx-2">
                                <b>El valor posible a deducir ha sido superado</b>
                            </span>
                        }
                      
                    </div>
                    <div className="col">
                        <div className="float-end">
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary btn-sm" id="dropdown-basic">
                                    Agregar Deducciones
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={newExpense}>Agregar Gasto</Dropdown.Item>
                                    <Dropdown.Item onClick={newAsset}>Agregar Activo</Dropdown.Item>
                                    <Dropdown.Item onClick={newPayment}>Agregar Pago de Deuda</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="card-body">
                  
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="col-5">Nombre</th> 
                            <th scope="col" className="col-4">Cuenta</th> 
                            <th scope="col" className="col-2">Monto</th> 
                            <th scope="col" className="col-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            item.deductions && item.deductions.map((d, index) => {
                                return (
                                    <tr key={index}>
                                        <td scope="col" className="col-5">{d.name}</td>
                                        <td scope="col" className="col-4">{d.account}</td>
                                        <td scope="col" className="col-2">
                                            <OutputField value={d.amount} vConfig={{type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY}} user={user}/>
                                        </td>
                                        <td scope="col" className="col-1">
                                            <div className="hstack gap-3 float-end">
                                                <a href="#" onClick={() => editHandler(d, index)} className="link-primary"><i className="bi bi-pencil" /></a>
                                                <a href="#" onClick={() => removeHandler(index)} className="link-danger"><i className="bi bi-trash" /></a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>

            <Modal show={showModal} onHide={closeModal} size="xl">
                <NewComponent item={item} configs={configs} user={user} close={closeModal}/>
            </Modal>
            {
                deduction && showEditModal &&
                <Modal show={true} onHide={closeModal} size="xl">
                    <NewComponent item={item} configs={configs} user={user} close={closeModal} oldItem={deduction} oldItemIndex={deductionIndex}/>
                </Modal>
            }
            
 
        </div>
    )
}

export default IncomeDeductionsComponent