import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container'

import * as uservice from '../../utilities/services/user-service'
import * as uactions from '../../utilities/redux/actions/user-slice';
import validator from '../user-validator'

const RequestPasswordPage = () => {
   
    const [email, setEmail] = useState('');
    const [validations, setValidations] = useState({});

    const dispatch = useDispatch()
    
    const navigate = useNavigate()

    const updateHandler = async (e, set) => { 
        set(e.target.value)
    }

    const submitHandler = async e => { 

        try {
            // validation
            if(!validator.validation({type: validator.REQUEST_PASSWORD_CHANGE, parameters: {email}, setInputs: setValidations}))
                return

            uservice.requestPassword({ email }, dispatch)
            navigate(`/login`)
        }
        catch(e) {
            // if you get some errors
        }

    }

    const cancelHandler = async e => { 
        // go to login page
        navigate(`/login`)
    }
  
    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5"> 
                
                    <h2 className="fs-5 fw-normalmb-4">Cambio de Contraseña</h2>

                    <div className="text-center mb-3">
                    
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Correo</label>
                                    <input type="email" className={`form-control ${validations.email? "is-invalid": ""}`} name="email" id="email" placeholder="name@example.com" value={email} required onChange={(e)=> updateHandler(e, setEmail)}/>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-secondary" type="button" onClick={cancelHandler}>Cancelar</button>
                                    </div>
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-primary" type="button" onClick={submitHandler}>Enviar Solicitud</button>
                                    </div>
                                </div>
                            </div>

                        
                        </div>
                    </form>
                </div>
            </div>
        </AnonymousFormContainer>
    )
}

export default RequestPasswordPage